// eslint-disable-next-line no-unused-vars
import React, { memo, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubmitButtonsField from '../../Buttons/SubmitButtonsField';
import * as builderActions from '../../../redux/actions/workflow-builder-actions.js';
import DropDown from '../../Inputs/DropDown';
import { Checkbox, CircularProgress, Tooltip } from '@mui/material';
import useEditTimeFormat from '../../../hooks/useEditTimeFormat';
import { convertDecimalToTime, convertToDecimal } from '../../../utilities/utilities';
import Select from '../../Inputs/Select';
import InputTaskDetails from '../../Inputs/InputTaskDetails';
import { useParams } from 'react-router';
import { setSnackBarIsOpen } from '../../../redux/actions/dashboard/workspaces-actions.js';

function WorkflowTaskModal({ onCancel, task, client_workflow_id }) {
	const dispatch = useDispatch();
	const { id } = useParams();

	const newTask = {
		client_workflow_id: client_workflow_id,
		task_type_id: 2,
		title: '',
		description: '',
		is_billable: true,
		budgeted_hours: undefined,
		assignee_user_id: null,
		dependent_task_id: null,
		required_days: 0,
		approval_from: null,
		is_approval_required: false,
		order: 1,
	};

	const [taskForm, setTaskFields] = useState(task ? task : newTask);
	const [formControls, setFormControls] = useState({
		loading: false,
		error: '',
	});
	const { inputValue, setInputValue, handleInputChange } = useEditTimeFormat(
		taskForm?.budgeted_hours,
	);

	const teamMembers = useSelector((state) => state.teamReducer.teamMembers);
	let allWorkflowTasks = useSelector(
		(state) => state.WorkflowBuilder.selectedClientWorkflow,
	)?.client_workflow_tasks;

	if (task) {
		allWorkflowTasks = allWorkflowTasks?.filter((item) => item.id != task.id);
	}

	const availableOptions = useMemo(() => {
		if (task) {
			const childAndGrandChild = [];

			const getDependents = (taskIdsArray) => {
				const temp = [];
				allWorkflowTasks?.map((item) => {
					if (taskIdsArray?.includes(item.dependent_task_id)) {
						childAndGrandChild.push(item.id);
						temp.push(item.id);
					}
				});
				if (temp.length != 0) {
					getDependents(temp);
				}
			};

			getDependents([task.id], allWorkflowTasks);

			return allWorkflowTasks?.filter((task) => !childAndGrandChild?.includes(task.id));
		} else {
			return allWorkflowTasks;
		}
	}, [allWorkflowTasks?.length]);

	const assignees = teamMembers?.map((member) => {
		return {
			...member,
			full_name: member.name,
			id: member.user_id,
			is_active: member.is_active,
		};
	});

	const handleInputBlur = () => {
		let value = 0;
		const name = 'budgeted_hours';

		if (inputValue === '') {
			return;
		}

		if (inputValue.includes(':')) {
			value = convertToDecimal(inputValue);
		} else if (!isNaN(inputValue)) {
			value = inputValue == 0 ? 0.1 : parseFloat(inputValue);
		}

		const formattedValue = convertDecimalToTime(value);
		setInputValue(formattedValue);
		setTaskFields({
			...taskForm,
			[name]: value,
		});
	};

	const quillHandlerDescription = (content, data, source) => {
		if (source == 'user') {
			setTaskFields({
				...taskForm,
				description: content,
			});
		}
	};

	const handleChange = (event) => {
		let { name, value } = event.target;

		if (name === 'dependent_task_id') {
			if (value === '' || value === 'null' || value === 0) {
				value = null;
			}
		}

		if (name == 'approval_from') {
			if (!isNaN(value) && value !== 0 && value !== null) {
				value = Number(value);
			} else {
				value = null;
			}
		}

		if (name == 'assignee_user_id') {
			if (!isNaN(value) && value !== 0 && value !== null) {
				value = Number(value);
			} else {
				value = null;
			}

			setTaskFields({
				...taskForm,
				approval_from: null,
			});
		}

		setTaskFields({
			...taskForm,
			[name]: value,
		});
	};

	const handleCheck = (event) => {
		let { name, value } = event.target;
		value = event.target.checked;
		if (!value) {
			const updatedForm = {
				...taskForm,
				[name]: value,
				approval_from: null,
			};
			setTaskFields(updatedForm);
		} else {
			setTaskFields({
				...taskForm,
				[name]: value,
			});
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		setFormControls({
			loading: true,
			error: '',
		});

		// if (!taskForm?.is_approval_required) {
		// 	delete taskForm?.approval_from;
		// }

		taskForm.budgeted_hours = Number(taskForm.budgeted_hours);
		// taskForm.required_days = Number(taskForm.required_days);
		delete taskForm.required_days;

		if (task) {
			dispatch(builderActions.updateClientWorkflowTask(taskForm))
				.then(() => {
					onCancel();
					setFormControls({
						loading: false,
						error: '',
					});
					dispatch(
						builderActions.getClientWorkflowTasks(id, taskForm?.client_workflow_id),
					)
						.then((response) => {
							dispatch(builderActions.setClientWorkflowTasks(response));
						})
						.catch((error) => {
							dispatch(setSnackBarIsOpen(false, true, error.message));
						});
				})
				.catch((error) => {
					setFormControls({
						loading: false,
						error: error.message,
					});
				});
		} else {
			dispatch(builderActions.addClientWorkflowTask(taskForm))
				.then(() => {
					onCancel();
					setFormControls({
						loading: false,
						error: '',
					});
					dispatch(
						builderActions.getClientWorkflowTasks(id, taskForm?.client_workflow_id),
					)
						.then((response) => {
							dispatch(builderActions.setClientWorkflowTasks(response));
						})
						.catch((error) => {
							dispatch(setSnackBarIsOpen(false, true, error.message));
						});
				})
				.catch((error) => {
					setFormControls({
						loading: false,
						error: error.message,
					});
				});
		}
	};

	return (
		<form autoComplete='off' className='ContactAdd-Form' onSubmit={handleSubmit}>
			<div
				className='p-3'
				style={{
					maxHeight: '75vh',
					height: 'fit-content',
					position: 'relative',
					overflowY: 'auto',
				}}
			>
				<div className='improved-input-designs pb-3'>
					<label className='require'>Task</label>
					<input
						className='w-100'
						type='text'
						name='title'
						value={taskForm?.title}
						onChange={handleChange}
						required={true}
					/>
				</div>
				<div className='improved-input-designs pb-3'>
					<label className=' '>Instructions</label>

					<InputTaskDetails
						richText
						type='text'
						name='description'
						onChange={quillHandlerDescription}
						value={taskForm?.description}
					/>
				</div>
				<div className='improved-input-designs pb-3'>
					<label className='require'>Budgeted Hours</label>
					<Tooltip
						title='Entered value will be converted to hh:mm format'
						placement='bottom-start'
					>
						<input
							className='w-100'
							type='text'
							value={inputValue}
							name='budgeted_hours'
							onChange={handleInputChange}
							onBlur={handleInputBlur}
							required={true}
							min={0.1}
							placeholder='HH : MM'
						/>
					</Tooltip>
				</div>

				{/* <div className='improved-input-designs pb-3'>
					<label className='require'>Required Days</label>
					<input
						className='w-100'
						type='number'
						value={taskForm?.required_days}
						name='required_days'
						onChange={handleChange}
						required={true}
						min={0}
					/>
				</div> */}

				<div className='improved-input-designs pb-3'>
					<label className=' '>Assignee</label>
					<DropDown
						onChange={handleChange}
						className='w-100 '
						type='text'
						value={assignees
							?.map((assignee) => {
								return {
									value: assignee.id,
									label: assignee.full_name,
								};
							})
							.find((item) => item.value === taskForm?.assignee_user_id)}
						name='assignee_user_id'
						options={assignees
							?.filter((assignee) => assignee?.id != Number(taskForm?.approval_from))
							?.map((assignee) => {
								return {
									value: assignee.id,
									label: assignee.full_name,
								};
							})}
					/>
				</div>
				<div className='improved-input-designs pb-3'>
					<label className=' '>Dependency(Optional)</label>
					<DropDown
						onChange={handleChange}
						className='w-100 '
						type='text'
						name='dependent_task_id'
						value={{
							value: allWorkflowTasks?.find(
								(item) => item.id === parseInt(taskForm?.dependent_task_id),
							)?.id,
						}}
						options={availableOptions?.map((item) => {
							return {
								label: item.title,
								value: item.id,
							};
						})}
					/>
				</div>

				<div className=' d-flex flex-row align-items-center'>
					<Checkbox
						className='input-style bg-white'
						sx={{
							background: 'transparent !important',
							color: 'grey !important',
							'&.Mui-checked': {
								color: '#FB7A03 !important',
							},
							'&.MuiCheckbox-root': {
								'&:hover': {
									boxShadow: 'none',
								},
							},
						}}
						style={{
							paddingLeft: 0,
						}}
						checked={taskForm?.is_approval_required}
						onChange={handleCheck}
						name='is_approval_required'
					/>
					<span className='pt-2'>
						<label
							className='BodyTwoLight align-self-center'
							style={{
								color: '#333333',
							}}
						>
							Require approval{' '}
						</label>
					</span>
				</div>

				{taskForm?.is_approval_required && (
					<>
						<div className='improved-input-designs pt-3 pb-3'>
							<label className='require text-capitalize'>Approval From</label>
							<Select
								onChange={handleChange}
								value={{ value: taskForm?.approval_from }}
								name='approval_from'
								options={assignees
									?.filter(
										(assignee) =>
											Number(taskForm?.assignee_user_id) != assignee.id,
									)
									?.map((assignee) => {
										return {
											value: assignee.id,
											label: assignee.full_name,
										};
									})}
								required={true}
							/>
						</div>

						{taskForm?.approval_from && (
							<div className='BodyTwoLight text-center w-100 Recurrence-text'>
								{
									assignees?.find(
										(assignee) =>
											Number(assignee.id) == Number(taskForm?.approval_from),
									)?.full_name
								}{' '}
								will be required to approve the task to mark this task complete.
								<br />
							</div>
						)}
					</>
				)}

				{formControls?.error && (
					<div className='pt-2'>
						<span className='error-text BodyTwoLight'>{formControls?.error}</span>
					</div>
				)}
			</div>

			<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
				<SubmitButtonsField child={true}>
					<button id='Element' className='secondary' onClick={onCancel}>
						Cancel
					</button>

					<button
						data-cy='add-task'
						type='submit'
						className='d-flex align-items-center'
						id='Element'
						disabled={formControls?.loading}
					>
						{formControls?.loading ? (
							<CircularProgress size={24} sx={{ color: 'white' }} disableShrink />
						) : taskForm?.id ? (
							'Update'
						) : (
							'Add'
						)}
					</button>
				</SubmitButtonsField>
			</div>
		</form>
	);
}

export default memo(WorkflowTaskModal);
