/* eslint-disable no-mixed-spaces-and-tabs */
import { Tooltip } from '@material-ui/core';
import { Avatar } from '@mui/material';
import moment from 'moment';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as CommentIcon } from '../../assets/icons/WhiteRead.svg';
import { ReactComponent as CommentIconFilled } from '../../assets/icons/WhiteUnread.svg';
import {
	TASK_STATUSES,
	TASK__DISPLAY_STATUSES,
	convertDecimalToTime,
} from '../../utilities/utilities';
import { getUserSettings } from '../../hooks/getUserSettings';
import { useSessionStorage } from '../../hooks/useSessionStorage';

function NewTaskCard({
	workflow_name,
	color,
	status,
	image,
	onTaskClick,
	onDragEnd,
	approvalFrom,
	taskId,
	containerClassName,
	onDragStart,
	due_date,
	budgeted_hours,
	title,
	assignee_name,
	assignee_id,
	ifTaskIsDraggable = true,
	dependecyOrder = null,
}) {
	const activeUser = useSelector((state) => state.workSpaceReducer.activeUser);
	const notifications = useSelector((state) => state.actionCenterReducer.notifications);
	const userDetails = useSelector((state) => state.authReducer.userDetails);
	const notificationMap = new Map(
		notifications?.map((notification) => [
			JSON.stringify(notification.metadata.task_id),
			{
				taskId: notification.metadata.task_id,
				status: notification.status,
				event: notification.event,
			},
		]),
	);
	const notification = notificationMap.get(JSON.stringify(taskId));
	const { short_date_format } = getUserSettings(userDetails);
	const isTaskMinimized = useSelector((state) => state.workSpaceReducer.isTaskMinimized);
	const active_task_id = useSessionStorage('active_task_id');
	const isTaskDraggable = isTaskMinimized
		? !(isTaskMinimized && active_task_id?.getSessionStorage() == taskId)
		: status !== TASK_STATUSES.COMPLETED;

	let taskOpacity = 1;

	if (status === TASK_STATUSES.COMPLETED) {
		taskOpacity = 0.4;
	} else {
		taskOpacity = 1;
	}
	return (
		<div
			onClick={onTaskClick(taskId)}
			onDragEnd={status === TASK_STATUSES.COMPLETED ? () => {} : onDragEnd}
			onDragStart={onDragStart}
			draggable={isTaskDraggable && ifTaskIsDraggable}
			style={{
				cursor: 'grab',
				boxSizing: 'border-box',
				borderTopRightRadius: '4px',
				borderTopLeftRadius: '4px',
				border: status === TASK_STATUSES.BLOCKED && '1px solid red',
			}}
			className={`task-shadow ${containerClassName}`}
		>
			<div
				className='workflow-container h-auto'
				style={{ backgroundColor: color || '#fb7a03', opacity: taskOpacity }}
			>
				<div
					style={{
						whiteSpace: 'nowrap',
						justifyContent: 'space-between',
						width: '87%',
						gap: '3px',
						overflow: 'hidden',
					}}
					className='d-flex'
				>
					<div>
						<span className='BodyThreeLight white-text'>
							{TASK__DISPLAY_STATUSES[status]}{' '}
						</span>
					</div>
					<div>
						<span className='BodyThreeLight white-text task-card-due-date'>
							Due {moment(due_date).format(short_date_format)}
						</span>
					</div>
					<div>
						<span className='BodyThreeLight white-text'>
							{budgeted_hours > 1
								? `${convertDecimalToTime(budgeted_hours)} Hrs`
								: `${convertDecimalToTime(budgeted_hours)} Hr`}{' '}
						</span>
					</div>
				</div>
				<div>
					{notification?.status === 'UNREAD' &&
					(notification.event === 'comment_mentioned' ||
						notification.event === 'comment_created') ? (
						<CommentIconFilled />
					) : (
						<CommentIcon />
					)}
					{/* {notifications?.filter(notification => ((notification.event === 'comment_mentioned' || 
                        notification.event === 'comment_created') && notification?.metadata?.task_id === taskDetails?.id && notification?.status === 'UNREAD'))?.length > 0 ? <CommentIconFilled /> : <CommentIcon />} */}
				</div>
			</div>
			<div className='task-card-container-lg h-100'>
				<div
					style={{ boxSizing: 'border-box' }}
					className='d-flex justify-content-between align-items-center'
				>
					<div
						style={{
							width: '90%',
							boxSizing: 'border-box',
							opacity: taskOpacity,
						}}
						className='d-flex align-items-center justify-content-between'
					>
						<Tooltip title={`${title} : ${workflow_name || 'Ad hoc Task'}`}>
							<div className='w-75'>
								<div
									className='BodyTwoBold text-left'
									style={{
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
									}}
								>
									{title}
								</div>

								<div
									className='BodyTwoLight text-left'
									style={{
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
									}}
								>
									{workflow_name || 'Ad hoc Task'}
								</div>
							</div>
						</Tooltip>
						<div className='BodyTwoLight'>
							<span>{dependecyOrder}</span>
						</div>
					</div>
					<div style={{ position: 'relative', opacity: taskOpacity }}>
						<Tooltip title={assignee_name || 'Unassigned'} placement='bottom'>
							<Avatar
								sx={{
									bgcolor: `#FEF2E7`,
									color: `#333`,
									height: '16px',
									width: '16px',
									fontSize: '0.8rem',
									cursor: 'pointer',
									boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.24)',
									border:
										activeUser?.user_ids?.length > 0 &&
										assignee_id &&
										((activeUser?.user_ids?.includes(assignee_id) &&
											activeUser?.task_id == taskId) ||
											(activeUser?.user_ids?.includes(approvalFrom) &&
												activeUser?.task_id == taskId &&
												status === TASK_STATUSES.APPROVAL_PENDING)) &&
										'3px solid #18C58D',
								}}
								alt=''
								src={image}
							/>
						</Tooltip>
					</div>
				</div>
				{/* {taskDetails?.depend_on_task && (
                    <div
                        className='d-flex text-left'
                        style={{
                            maxWidth: '100%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            boxSizing: 'border-box',
                            opacity: taskOpacity,
                        }}
                    >
                        <Tooltip title={taskDetails?.depend_on_task?.title}>
                            <span className='BodyTwoLight' style={{ color: '#696F6C' }}>
                                {taskDetails?.depend_on_task?.title}
                            </span>
                        </Tooltip>
                    </div>
                )} */}
			</div>
		</div>
	);
}

export default memo(NewTaskCard);
