import React, { memo, useEffect, useRef, useState } from 'react';
import { ReactComponent as TaskIcon } from '../../assets/icons/ClipboardText.svg';
import { ReactComponent as Ellipse } from '../../assets/icons/Ellipse.svg';

import ClientWorkflowTask from './ClientWorkflowTask';
import * as builderActions from '../../redux/actions/workflow-builder-actions.js';
import Restricted from '../Permissions/Restricted';
import { standardPermissions } from '../../utilities/utilities';
import WorkflowTaskModal from './Modals/WorkflowTaskModal';
import DummyModal from '../Modal/DummyModal';
import { CircularProgress, Modal } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useModal from '../Modal/useModal';
import { useDispatch } from 'react-redux';
import TaskAlert from '../Alerts/TaskAlert.jsx';
import { setSnackBarIsOpen } from '../../redux/actions/dashboard/workspaces-actions.js';
import { useParams } from 'react-router';

function ClientWorkflowTasksComponent({
	sortedWorkflowTasks,
	user_team_role,
	assignees,
	client_workflow_id,
}) {
	const dispatch = useDispatch();
	const { id } = useParams();
	const [expandTasks, setExpandTasks] = useState(true);
	const [expandedStates, setExpandedStates] = useState([]);
	const [isAlert, setIsAlert] = useState(false);
	const [updateTaskOrderLoading, setUpdateTaskOrderLoading] = useState(false);

	const [tasks, setTasks] = useState([]);
	const [previousOrder, setPreviousOrder] = useState([]);
	const [draggedTask, setDraggedTask] = useState(false);
	const dragOverRef = useRef(null);

	const toggleExpandAll = () => {
		setExpandTasks(!expandTasks);
		setExpandedStates(new Array(tasks.length).fill(!expandTasks));
	};

	const handleExpandChange = (index, isExpanded) => {
		const updatedExpandedStates = [...expandedStates];
		updatedExpandedStates[index] = isExpanded;

		setExpandedStates(updatedExpandedStates);

		const allExpanded = updatedExpandedStates.every((state) => state);
		const allCollapsed = updatedExpandedStates.every((state) => !state);

		if (allExpanded) {
			setExpandTasks(true);
		}
		if (allCollapsed) {
			setExpandTasks(false);
		}
	};

	const handleDragStart = (e, id) => {
		setDraggedTask(id);
		e.dataTransfer.effectAllowed = 'move';
	};

	const handleDragOver = (e, dragOverTask) => {
		e.preventDefault();

		if (!draggedTask || !dragOverTask || draggedTask.id === dragOverTask.id) return;

		const draggedIndex = tasks.findIndex((task) => task.id === draggedTask.id);
		const targetIndex = tasks.findIndex((task) => task.id === dragOverTask.id);

		if (draggedIndex === -1 || targetIndex === -1 || draggedIndex === targetIndex) return;

		const updatedTasks = [...tasks];
		const [draggedItem] = updatedTasks.splice(draggedIndex, 1);
		updatedTasks.splice(targetIndex, 0, draggedItem);

		setTasks(
			updatedTasks?.map((task, index) => {
				return {
					...task,
					dependency_order: Number(index),
				};
			}),
		);

		dragOverRef.current = dragOverTask;
	};

	const handleDragEnd = () => {
		setDraggedTask(null);

		if (!draggedTask || !dragOverRef.current) {
			setTasks(previousOrder);
			return;
		}

		const draggedIndex = tasks.findIndex((task) => task.id === draggedTask.id);
		if (draggedIndex === draggedTask?.dependency_order) {
			return;
		}
		const parentTask = tasks.find((task) => task.id === draggedTask.dependent_task_id);
		const childTasks = tasks.filter((task) => task.dependent_task_id === draggedTask.id);

		const parentIndex = parentTask ? tasks.findIndex((task) => task.id === parentTask.id) : -1;
		if (parentIndex > draggedIndex) {
			setTasks(previousOrder);
			setIsAlert(true);
			return;
		}

		for (const child of childTasks) {
			const childIndex = tasks.findIndex((task) => task.id === child.id);
			if (childIndex < draggedIndex) {
				setTasks(previousOrder);
				setIsAlert(true);
				return;
			}
		}
		setUpdateTaskOrderLoading(true);
		dispatch(
			builderActions.updateClientWorkflowTasksDependencyOrder({
				tasks: tasks?.map((task, index) => {
					return {
						id: task?.id,
						dependency_order: index,
					};
				}),
			}),
		)
			.then(() => {
				setPreviousOrder(tasks);
				setUpdateTaskOrderLoading(false);
			})
			.catch((error) => {
				setTasks(previousOrder);
				dispatch(setSnackBarIsOpen(false, true, error.message));
				setUpdateTaskOrderLoading(false);
			});
	};

	useEffect(() => {
		setTasks(sortedWorkflowTasks || []);
		setPreviousOrder(sortedWorkflowTasks || []);
	}, [sortedWorkflowTasks]);

	useEffect(() => {
		setExpandedStates((prevStates) => {
			if (tasks.length > prevStates.length) {
				return [...prevStates, ...new Array(tasks.length - prevStates.length).fill(true)];
			} else if (tasks.length < prevStates.length) {
				return prevStates.slice(0, tasks.length);
			}
			return prevStates;
		});
	}, [tasks.length]);

	return (
		<div className='client-workflow-task-section'>
			<div className='d-flex task-section-header justify-content-between align-items-center w-100'>
				<span className='d-flex align-items-center' style={{ gap: '12px' }}>
					<TaskIcon />
					<h3 className='HeadlineThreeBold'>Tasks</h3>
				</span>
				<div className='d-flex align-items-center justify-content-end'>
					<span
						className='BodyTwoLight primary-text mt-1 pointer'
						onClick={toggleExpandAll}
					>
						{expandTasks ? 'Collapse Tasks' : 'Expand Tasks'}
					</span>
					<span className='ml-3'>
						<Ellipse />
					</span>
					<Restricted AllowedUsers={standardPermissions} userTeamRole={[user_team_role]}>
						<AddNewTask client_workflow_id={client_workflow_id} />
					</Restricted>
				</div>
			</div>
			{tasks?.map((task, index, total_tasks) => {
				const dependent_task_name = total_tasks?.find(
					(item) => item.id == task.dependent_task_id,
				)?.title;
				const taskAssignee = assignees?.find((u) => u.id == task.assignee_user_id);
				return (
					<ClientWorkflowTask
						key={task.id}
						index={index}
						task={task}
						dependency_order={`${parseInt(task?.dependency_order) + 1}/${
							tasks?.length
						}`}
						dependent_task_name={dependent_task_name}
						user_team_role={user_team_role}
						assignee_user={taskAssignee}
						expandTasks={expandTasks}
						handleDragStart={handleDragStart}
						handleDragOver={handleDragOver}
						handleDragEnd={handleDragEnd}
						draggedTask={draggedTask}
						onExpandChange={handleExpandChange}
						client_workflow_id={client_workflow_id}
						client_id={id}
					/>
				);
			})}

			<Restricted AllowedUsers={standardPermissions} userTeamRole={[user_team_role]}>
				<AddNewTask
					client_workflow_id={client_workflow_id}
					style={{
						boxShadow: '0px 8px 24px 0px #959DA533',
						width: '100%',
					}}
				/>
			</Restricted>

			<TaskAlert
				message={
					'You cannot move this task before the dependent task. To place it to the desired position, you can remove the dependency.'
				}
				open={isAlert}
				header={'This task cannot be moved before the dependent task'}
				closeButton={'Cancel'}
				closeHandler={() => setIsAlert(false)}
			/>

			{updateTaskOrderLoading && (
				<div
					style={{
						height: '100%',
						width: '100%',
						backgroundColor: 'rgba(0, 0, 0, 0.05)',
						position: 'fixed',
						top: 0,
						bottom: 0,
						right: 40,
					}}
				>
					<CircularProgress
						style={{
							color: '#fa7b03',
							height: '50px',
							width: '50px',
							marginTop: '47vh',
						}}
					/>
				</div>
			)}
		</div>
	);
}

export default ClientWorkflowTasksComponent;

const AddNewTaskComponent = ({ client_workflow_id, style }) => {
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

	const onAddNewClick = () => {
		ModalType('CLIENT_WORKFLOW_TASK');
		toggleModal();
	};

	return (
		<div
			className='d-flex justify-content-center information-header d-flex align-items-center'
			style={style}
		>
			<div
				className='pointer d-flex flex-row align-items-center'
				style={{
					height: '22px',
					width: '6px',
					borderRadius: '3px',
					marginRight: '1rem',
					padding: 0,
					position: 'relative',
				}}
				onClick={toggleModal}
			>
				<AddIcon
					fontSize='small'
					sx={{
						color: '#fb7a03',
						fontSize: 21,
						position: 'absolute',
						margin: 0,
						padding: 0,
						top: 0,
						left: 0,
					}}
				/>
			</div>
			<h3
				className='BodyTwoLight mt-2 dark-text'
				style={{
					color: '#FB7A03',
					cursor: 'pointer',
				}}
				onClick={onAddNewClick}
			>
				Add New Task
			</h3>

			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						{ModalName == 'CLIENT_WORKFLOW_TASK' && (
							<DummyModal
								style={{ width: '900px' }}
								hideCrossIcon
								title='Task Details'
								onClose={toggleModal}
							>
								<WorkflowTaskModal
									client_workflow_id={client_workflow_id}
									onCancel={toggleModal}
								/>
							</DummyModal>
						)}
					</>
				</Modal>
			)}
		</div>
	);
};

const AddNewTask = memo(AddNewTaskComponent);
