/* eslint-disable no-mixed-spaces-and-tabs */
import { useEffect, useState } from 'react';
import { ReactComponent as ClipBoardIcon } from '../../assets/icons/ClipboardText.svg';
import { ReactComponent as ClockIcon } from '../../assets/icons/ApprovalClock.svg';
import { ReactComponent as StackIcon } from '../../assets/icons/Stack.svg';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ReactHtmlParser from 'react-html-parser';

export default function WorkflowTaskCard({
	title,
	budgetedHours,
	description,
	dependencyTitle,
	dependencyOrder,
	allExpand,
	index,
	handleExpandChange,
}) {
	const [expandTask, setExpandTask] = useState(allExpand);

	const toggleExpand = () => {
		setExpandTask(!expandTask);
		handleExpandChange(index, !expandTask);
	};

	useEffect(() => {
		setExpandTask(allExpand);
	}, [allExpand]);
    
	return (
		<>
			<div className='task-section'>
				<div className='d-flex justify-content-between'>
					<div className='d-flex align-items-center'>
						<div style={{ marginRight: '8px' }}>
							<ClipBoardIcon />
						</div>
						<span className='HeadlineThreeBold'>{title}</span>
						<span className='BodyOneLight ml-2'>{dependencyOrder}</span>
					</div>
					<div className='pointer' onClick={toggleExpand}>
						{expandTask ? (
							<KeyboardArrowUpIcon sx={{ color: '#FB7A03' }} />
						) : (
							<KeyboardArrowDownIcon sx={{ color: '#FB7A03' }} />
						)}
					</div>
				</div>
				{expandTask && (
					<div className='h-100'>
						<div
							className='d-flex align-items-center'
							style={{ marginTop: '17px', gap: '12px' }}
						>
							<div className='d-flex align-items-center'>
								<ClockIcon />
								<div className='pl-1'>
									<span className='BodyTwoBold'>
										Budgeted Hours:{' '}
										<span className='BodyTwoLight'>{budgetedHours}</span>
									</span>
								</div>
							</div>
							<div className='d-flex align-items-center'>
								<StackIcon />
								<div className='pl-1'>
									<span className='BodyTwoBold'>
										Dependency:{' '}
										<span className='BodyTwoLight'>{dependencyTitle}</span>
									</span>
								</div>
							</div>
						</div>

						<div
							className='task-section-body'
							style={{ maxHeight: '190px', overflow: 'auto' }}
						>
							<div>
								<span className='BodyTwoLight'>
									{description?.length < 1 ||
									description == null ||
									description?.replace(/<[^>]+>/g, '')?.trim()?.length == 0 ? (
										'No description available'
									) : (
										<div className='d-flex flex-column justify-content-between position-relative'>
											<p className='BodyTwoLight dark-text m-0 text-break'>
												{ReactHtmlParser(description)}
											</p>
										</div>
									)}
								</span>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
}
