import { useState } from 'react';
import SubmitButtonsField from '../Buttons/SubmitButtonsField';

function EditWorkflowBuilderDescription({ closeModal, description, onSubmit }) {
	const [workflowDescription, setWorkflowDescription] = useState(description);
	const [loading, setLoading] = useState(false);

	const updateDescription = async () => {
		setLoading(true);
		await onSubmit({ description: workflowDescription });
		setLoading(false);
		closeModal();
	};

	return (
		<div>
			<div className='VerticalSpacer'>
				<div className='improved-input-designs p-3'>
					<label>Workflow Description</label>
					<textarea
						className='w-100'
						rows={5}
						value={workflowDescription}
						name='name'
						onChange={(e) => setWorkflowDescription(e.target.value)}
					/>
				</div>
				<SubmitButtonsField
					rootClass='bg-white p-3'
					title={'Save'}
					onCancel={closeModal}
					onSubmit={updateDescription}
					isLoading={loading}
				/>
			</div>
		</div>
	);
}

export default EditWorkflowBuilderDescription;
