/* eslint-disable no-mixed-spaces-and-tabs */
import moment from 'moment';
import CalendarIcon from '../assets/icons/Calendarcolor.svg';
import Car from '../assets/icons/Car.svg';
import SmileyNervous from '../assets/icons/SmileyNervous.svg';
import Globe from '../assets/icons/GlobeHemisphereWest.svg';
import House from '../assets/icons/HouseLine.svg';
// import { io } from 'socket.io-client';

export const numberRegex = /^\d*$/;

export function splitFullName(fullName) {
	if (!fullName || typeof fullName !== 'string') return { firstName: '', lastName: '' };

	const nameParts = fullName.trim().split(' ');
	const firstName = nameParts[0] || ''; // First name is the first part
	const lastName = nameParts.slice(1).join(' ') || ''; // Last name is the rest

	return { firstName, lastName };
}

export const inviteStatusMap = {
	1: { label: 'Accepted', colorClass: 'green-text' },
	0: { label: 'Pending', colorClass: 'primary-text' },
	'-1': { label: 'Expired', colorClass: 'red-text' },
};

export const setCookie = function (cname, cvalue, exdays) {
	const d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);

	const expires = 'expires=' + d.toUTCString();
	document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

export const isLocalhost = window.location.hostname === 'localhost';

export const getCookie = function (cname) {
	const ca = document.cookie.split(';');

	const token = ca.find((c) => {
		return c.replace(' ', '').split('=')[0] === cname;
	});

	if (!token) {
		return '';
	}

	return token.split('=')[1];
};

export const getCapatalizeString = function (text) {
	return text?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter?.toUpperCase());
};

export const validateEmailError = (input) => {
	if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(input)) {
		if (!input) {
			return 'Required';
		} else {
			return 'Please enter a valid email';
		}
	} else {
		return false;
	}
};

export const requiredError = (input) => {
	if (!input) {
		return 'Required';
	} else {
		return false;
	}
};

export const passwordCheckError = (input) => {
	if (
		!/(^(?=.*[~|`|!|@|#|$|%|^|&|*|(|)|_|=|+|-])[0-9a-zA-Z~`!@#$%^,.&*()_=+-]{10,})/i.test(input)
	) {
		return 'Password must have at least 10 characters and must include 1 capital letter, 1 number, and 1 special character';
	} else {
		return false;
	}
};

export const matchPasswordError = (inputA, inputB) => {
	if (inputA !== inputB) {
		return 'Password and confirm password must match';
	} else {
		return false;
	}
};

export function hasNumber(myString) {
	return /\d/.test(myString);
}
let eventGuid = 0;
const todayStr = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
	{
		id: createEventId(),
		title: 'All-day event',
		start: todayStr,
	},
	{
		id: createEventId(),
		title: 'Timed event',
		start: todayStr + 'T12:00:00',
	},
];

export const getIsWeekend = () => {
	return localStorage.getItem('weekend');
};

export const getIsMonthly = () => {
	return localStorage.getItem('monthly');
};
export const setIsWeekend = (value) => {
	return localStorage.setItem('weekend', value);
};

export const setIsMonthly = (value) => {
	return localStorage.setItem('monthly', value);
};

export function createEventId() {
	return String(eventGuid++);
}

export const getWeekString = (momentObj) => {
	const year = String(momentObj.year());
	const month = String(momentObj.month() + 1).padStart(2, '0');
	const day = String(momentObj.date()).padStart(2, '0');
	return `${year}-${month}-${day}`;
};
export const pattern = /(^.\d{3})/g;
export const getCurrentBeginningOfWeekString = () => {
	const beginningOfWeek = moment().startOf('isoWeek');
	return getWeekString(beginningOfWeek);
};

export const generateUniqueId = () => {
	return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

export function areArraysEqual(arr1, arr2) {
	// Check if arrays have the same length
	if (arr1.length !== arr2.length) {
		return false;
	}

	// Sort the arrays
	arr1.sort();
	arr2.sort();

	// Check if each element is equal
	for (let i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) {
			return false;
		}
	}

	// If all elements are equal and lengths are same, arrays are equal
	return true;
}

export const generateWeekDatesFromDate = (beginningOfWeek, weekends) => {
	let momentObj = beginningOfWeek;
	if (!beginningOfWeek.isValid()) {
		momentObj = moment().startOf('isoWeek');
	}
	const daysOfTheWeek = [];
	const endOfFullWeek = moment(momentObj).endOf('isoWeek');
	const endOfWorkWeek = moment(momentObj).add(4, 'd').endOf('day');
	const weekend = weekends ? 7 : 5;
	daysOfTheWeek.push(momentObj);

	for (let i = 1; i < weekend; i += 1) {
		daysOfTheWeek.push(moment(momentObj).add(i, 'd'));
	}

	return {
		beginningOfWeek: momentObj,
		daysOfTheWeek,
		endOfFullWeek,
		endOfWorkWeek,
	};
};

export const getMonthlyDate = (date) => {
	const startOfMonth = moment(date).clone().startOf('month');
	const endOfMonth = moment(date).clone().endOf('month');
	const startOfWeek = startOfMonth.clone().startOf('month').weekday(1);
	const endOfWeek = endOfMonth.clone().endOf('week').add(1, 'day');
	return {
		startOfMonth: startOfMonth,
		endOfMonth: endOfMonth,
		startOfWeek: startOfWeek,
		endOfWeek: endOfWeek,
		monthStartDate: startOfMonth,
	};
};

export const hideAccountNumber = (accountNumber) => {
	const number = Object.assign([], accountNumber);
	for (let i = 0; i < number.length; i++) {
		if (i == number.length - 4) {
			break;
		}
		number[i] = '*';
	}
	return number.join('');
};

export const inputDateFormate = (str) => {
	var date = new Date(str),
		mnth = ('0' + (date.getMonth() + 1)).slice(-2),
		day = ('0' + date.getDate()).slice(-2);
	return [date.getFullYear(), mnth, day].join('-');
};

export const convertUnixDate = (date) => {
	return moment.unix(date)?.format('MMMM D, YYYY');
};
export function getUniqueListBy(arr, key) {
	return [...new Map(arr.map((item) => [item[key], item])).values()];
}

export const TASK_STATUSES = {
	NEW: 'NEW',
	IN_PROGRESS: 'IN_PROGRESS',
	PAST_DUE: 'PAST_DUE',
	BLOCKED: 'BLOCKED',
	COMPLETED: 'COMPLETED',
	APPROVAL_PENDING: 'APPROVAL_PENDING',
};

export const NOTIFICATION_STATUS = {
	READ: 'READ',
	UNREAD: 'UNREAD',
};

export const TASK__DISPLAY_STATUSES = {
	NEW: 'Not Started',
	IN_PROGRESS: 'In Progress',
	PAST_DUE: 'Past Due',
	BLOCKED: 'Blocked',
	COMPLETED: 'Completed',
	APPROVAL_PENDING: 'Approval Pending',
};

export const REPEAT_TYPE = {
	DAILY: 'DAILY',
	WEEKLY: 'WEEKLY',
	MONTHLY: 'MONTHLY',
	YEARLY: 'ANNUALLY',
};

export const TASK_TYPE = {
	MEETING: 'MEETING',
	ADHOC: 'ADHOC',
};

export const detailsModal = {
	CLIENT: 'CLIENT',
	MY_TASK: 'MY_TASK',
};

export const EVENT_NOTIFICATIONS = {
	LEAVE_REQUEST_CREATED: 'leave_request_created',
	MY_TASK_INVITED: 'my_task_invited',
	MEETING_INVITE: 'meeting_invite',
	MEETING_INVITE_ACCEPTED: 'meeting_invite_accepted',
	MEETING_INVITE_REJECTED: 'meeting_invite_rejected',
	BULK_MEETING_INVITE_ACCEPTED: 'bulk_meeting_invite_accepted',
	BULK_MEETING_INVITE_REJECTED: 'bulk_meeting_invite_rejected',
};

export const MEETING_ACCEPT_STATUS = Object.freeze({
	PENDING: 'PENDING',
	ACCEPTED: 'ACCEPTED',
	REJECTED: 'REJECTED',
});

export const TRACKING_STATUS = {
	STARTED: 'started',
	IN_PROGRESS: 'in_progress',
	ENDED: 'ended',
};

export const getUniqueObjectArray = (array) => {
	const filteredArray = [];

	array?.map((obj) => {
		if (!filteredArray?.find((o) => o.value === obj.value)) {
			filteredArray?.push(obj);
		}
	});

	return filteredArray;
};

export const months = [
	{ value: 'January', label: 'January' },
	{ value: 'February', label: 'February' },
	{ value: 'March', label: 'March' },
	{ value: 'April', label: 'April' },
	{ value: 'May', label: 'May' },
	{ value: 'June', label: 'June' },
	{ value: 'July', label: 'July' },
	{ value: 'August', label: 'August' },
	{ value: 'September', label: 'September' },
	{ value: 'October', label: 'October' },
	{ value: 'November', label: 'November' },
	{ value: 'December', label: 'December' },
];

export function removeHttp(url) {
	url = url.toLowerCase();
	if (url.startsWith('https://')) {
		const https = 'https://';
		return url.slice(https.length);
	}

	if (url.startsWith('http://')) {
		const http = 'http://';
		return url.slice(http.length);
	}

	return url;
}
export const Member = {
	teamMemberId: 14,
	supervisorId: 12,
	superAdminId: 11,
	teamManagerId: 13,
	ownerId: 16,
	clientId: 15,
	externalCollaborator: 17,
};

export const featureFlagTenants = [10001, 10002, 10003];

export const getRoleName = (roleId) => {
	switch (roleId) {
		case Member.supervisorId:
			return 'Supervisor';
		case Member.teamMemberId:
			return 'Team Member';
		case Member.ownerId:
			return 'Owner';
		case Member.superAdminId:
			return 'Super Admin';
		case Member.teamManagerId:
			return 'Team Manager';
		case Member.externalCollaborator:
			return 'External Collaborator';
		default:
			return '';
	}
};

export const getRecurrenceType = (type) => {
	switch (type) {
		case REPEAT_TYPE.DAILY:
			return 'day';
		case REPEAT_TYPE.MONTHLY:
			return 'month';
		case REPEAT_TYPE.WEEKLY:
			return 'week';
		case REPEAT_TYPE.YEARLY:
			return 'year';
		default:
			return '';
	}
};

export function formatDate(dateString) {
	const options = { year: 'numeric', month: 'long', day: 'numeric' };
	return new Date(dateString).toLocaleDateString('en-US', options);
}

export function getFormattedDate(date) {
	var year = date.getFullYear();

	var month = (1 + date.getMonth()).toString();
	month = month.length > 1 ? month : '0' + month;

	var day = date.getDate().toString();
	day = day.length > 1 ? day : '0' + day;

	return month + '/' + day + '/' + year;
}

export function getFormattedDate2(date) {
	const str = date;

	const [day, month, year] = str?.split('/');

	return month + '/' + day + '/' + year;
}

export function formatPhoneNumber(phoneNumber = '') {
	const cleanNum = phoneNumber.toString().replace(/\D/g, '');
	const match = cleanNum.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);
	if (match) {
		return '(' + match[1] + ') ' + (match[2] ? match[2] + '-' : '') + match[3];
	}
	return cleanNum;
}

export function ordinal_suffix_of(i) {
	var j = i % 10,
		k = i % 100;
	if (j == 1 && k != 11) {
		return i + 'st';
	}
	if (j == 2 && k != 12) {
		return i + 'nd';
	}
	if (j == 3 && k != 13) {
		return i + 'rd';
	}
	return i + 'th';
}

export const nthWeekdayOfMonth = (year, month, nth, dow) => {
	const d = new Date(year, month - 1, 7 * (nth - 1) + 1);
	const w = d.getDay();
	d.setDate(d.getDate() + ((7 + dow - w) % 7));
	return d;
};

export const weekOfMonth = (date) => {
	const currentDate = moment(date);
	const nthOfMoth = Math.ceil(currentDate.date() / 7);
	return nthOfMoth;
};

export const incDate = (date, type, repeat_time) => {
	const newDate = new Date(moment(date).add(repeat_time, type));
	const year = newDate.getFullYear();
	const month = newDate.getMonth() + 1;
	const weekNo = weekOfMonth(moment(date));
	const dayOfWeek = moment(date).isoWeekday();

	const format = 'YYYY-MM-DD 00:00:00+00';

	if (type === 'weeks') {
		return moment(newDate).format(format);
	} else if (type === 'days') {
		return moment(newDate).format(format);
	} else {
		return moment(nthWeekdayOfMonth(year, month, weekNo, dayOfWeek)).format(format);
	}
};

export const getWeeksInAMonth = (currentDate) => {
	const startOfMonth = moment(currentDate).startOf('month');
	const endOfMonth = moment(currentDate).endOf('month');
	return moment(endOfMonth).week() - moment(startOfMonth).week() + 1;
};

export const SocketConnection = ({ socket, user, taskDetails, selected }) => {
	if (taskDetails && !taskDetails?.is_tentative) {
		if (
			(user?.userId === taskDetails?.user_profile?.id ||
				(taskDetails?.is_approval_required &&
					user?.userId === taskDetails?.approval_from)) &&
			taskDetails?.status !== 'BLOCKED' &&
			taskDetails?.status !== 'COMPLETED'
		) {
			if (selected) {
				socket.connect();
				socket.on('updated_task', (data) => {
					console.log(data);
				});
				socket.emit('start_tracking', {
					user_id: user?.userId,
					task_id: taskDetails?.id,
				});

				socket.on('connect', () => {
					console.log(socket.connected);
				});
			}
		}
	}
};

export const socketDisconnect = ({ socket }) => {
	socket.disconnect();
};

export function capitalize(s) {
	return s && s[0].toUpperCase() + s.slice(1);
}

export const PROTECTION_KEY = 'leonidus9182736455';

export function arrayMove(arr, fromIndex, toIndex) {
	var element = arr[fromIndex];
	arr.splice(fromIndex, 1);
	arr.splice(toIndex, 0, element);
	return arr;
}

export const sortByOrder = (arr) =>
	arr?.sort(function (a, b) {
		return a.order - b.order;
	});

export const objectBeingDragged = Object.freeze({
	OFFICE_LOCATION: 'OFFICE_LOCATION',
	CLIENT_CONTACTS: 'CLIENT_CONTACTS',
	CLIENT_LINKS: 'CLIENT_LINKS',
	SOFTWARE: 'SOFTWARE',
	CREDIT_CARD_ACCOUNT: 'CREDIT_CARD_ACCOUNT',
	BANK_ACCOUNT: 'BANK_ACCOUNT',
	MERCHANT_ACCOUNT: 'MERCHANT_ACCOUNT',
	PAYMENT_PORTAL: 'PAYMENT_PORTAL',
	SHAREDLINS: 'SHAREDLINS',
});

export const nthDayInMonth = (year, month, dayOfWeek) => {
	const date = moment()
		.year(year)
		.month(month - 1)
		.endOf('month');
	while (date.day() !== dayOfWeek) {
		date.subtract(1, 'days');
	}
	return Math.ceil(date.date() / 7);
};

export const getAvatarLabel = (name) => {
	name = name?.split(' ');
	name = name?.map((n) => n[0])?.join('');
	return name?.charAt(0) + name?.charAt(1);
};

export const workflowCategories = [
	{ value: 'Legal', label: 'Legal' },
	{ value: 'Financial', label: 'Financial' },
	{ value: 'Human Resources', label: 'Human Resources' },
];

export const primaryPermissions = [Member.teamMemberId];

export const standardPermissions = [
	Member.supervisorId,
	Member.superAdminId,
	Member.ownerId,
	Member.teamManagerId,
];

export const executivePermissions = [Member.supervisorId, Member.superAdminId, Member.ownerId];

export const isTeamManager = (userRoles) => {
	return userRoles?.includes(Member.teamManagerId);
};

export const getAccountAccessOfRoles = (role_id) => {
	switch (role_id) {
		case Member.ownerId:
			return [Member.ownerId];
		case Member.supervisorId:
			return executivePermissions;
		case Member.teamManagerId:
			return standardPermissions;
		case Member.teamMemberId:
			return [...standardPermissions, Member.teamMemberId];
		case Member.externalCollaborator:
			return [...standardPermissions, Member.teamMemberId, Member.externalCollaborator];
		default:
			return [];
	}
};

export const timeList = [
	{ value: '12:00 AM', label: '12:00 AM' },
	{ value: '12:30 AM', label: '12:30 AM' },
	{ value: '1:00 AM', label: '1:00 AM' },
	{ value: '1:30 AM', label: '1:30 AM' },
	{ value: '2:00 AM', label: '2:00 AM' },
	{ value: '2:30 AM', label: '2:30 AM' },
	{ value: '3:00 AM', label: '3:00 AM' },
	{ value: '3:30 AM', label: '3:30 AM' },
	{ value: '4:00 AM', label: '4:00 AM' },
	{ value: '4:30 AM', label: '4:30 AM' },
	{ value: '5:00 AM', label: '5:00 AM' },
	{ value: '5:30 AM', label: '5:30 AM' },
	{ value: '6:00 AM', label: '6:00 AM' },
	{ value: '6:30 AM', label: '6:30 AM' },
	{ value: '7:00 AM', label: '7:00 AM' },
	{ value: '7:30 AM', label: '7:30 AM' },
	{ value: '8:00 AM', label: '8:00 AM' },
	{ value: '8:30 AM', label: '8:30 AM' },
	{ value: '9:00 AM', label: '9:00 AM' },
	{ value: '9:30 AM', label: '9:30 AM' },
	{ value: '10:00 AM', label: '10:00 AM' },
	{ value: '10:30 AM', label: '10:30 AM' },
	{ value: '11:00 AM', label: '11:00 AM' },
	{ value: '11:30 AM', label: '11:30 AM' },
	{ value: '12:00 PM', label: '12:00 PM' },
	{ value: '12:30 PM', label: '12:30 PM' },
	{ value: '1:00 PM', label: '1:00 PM' },
	{ value: '1:30 PM', label: '1:30 PM' },
	{ value: '2:00 PM', label: '2:00 PM' },
	{ value: '2:30 PM', label: '2:30 PM' },
	{ value: '3:00 PM', label: '3:00 PM' },
	{ value: '3:30 PM', label: '3:30 PM' },
	{ value: '4:00 PM', label: '4:00 PM' },
	{ value: '4:30 PM', label: '4:30 PM' },
	{ value: '5:00 PM', label: '5:00 PM' },
	{ value: '5:30 PM', label: '5:30 PM' },
	{ value: '6:00 PM', label: '6:00 PM' },
	{ value: '6:30 PM', label: '6:30 PM' },
	{ value: '7:00 PM', label: '7:00 PM' },
	{ value: '7:30 PM', label: '7:30 PM' },
	{ value: '8:00 PM', label: '8:00 PM' },
	{ value: '8:30 PM', label: '8:30 PM' },
	{ value: '9:00 PM', label: '9:00 PM' },
	{ value: '9:30 PM', label: '9:30 PM' },
	{ value: '10:00 PM', label: '10:00 PM' },
	{ value: '10:30 PM', label: '10:30 PM' },
	{ value: '11:00 PM', label: '11:00 PM' },
	{ value: '11:30 PM', label: '11:30 PM' },
];

export const startTimeOptions = () => {
	const timeOptions = [];
	const currentTime = moment().startOf('day');
	const endTimeLimit = moment().endOf('day');

	while (currentTime.isSameOrBefore(endTimeLimit)) {
		const timeValue = currentTime.format('hh:mm A');
		timeOptions.push({ label: timeValue, value: timeValue });
		currentTime.add(15, 'minutes');
	}
	return timeOptions;
};

export const endTimeOptions = (start_time) => {
	const timeOptions = startTimeOptions()
		?.filter((time) => moment(time.value, 'hh:mm A').isAfter(moment(start_time, 'hh:mm A')))
		?.map((time) => ({ ...time }));
	timeOptions.push({ label: '11:59 PM', value: '11:59 PM' });
	return timeOptions;
};

export class workflowReusableUtils {
	constructor(array, from, to) {
		this.array = array;
		this.from = from;
		this.to = to;
		this.id = array[0]?.id ? 'id' : '#id';
	}
	get swapWorkflowtask() {
		const swappedArray = this.array.map((item) => {
			if (item[this.id] === this.from[this.id]) {
				return {
					...this.from,
					dependent_task_id:
						this.from[this.id] === this.to.dependent_task_id
							? this.to[this.id]
							: this.to.dependent_task_id,
					order: this.to.order,
				};
			} else if (item[this.id] === this.to[this.id]) {
				return {
					...this.to,
					dependent_task_id:
						this.to[this.id] === this.from.dependent_task_id
							? this.from[this.id]
							: this.from.dependent_task_id,
					order: this.from.order,
				};
			} else {
				if (item?.dependent_task_id === this.to[this.id]) {
					return { ...item, dependent_task_id: this.from[this.id] };
				} else if (item?.dependent_task_id === this.from[this.id]) {
					return { ...item, dependent_task_id: this.to[this.id] };
				} else {
					return item;
				}
			}
		});
		return swappedArray.sort((a, b) => a.order - b.order);
	}
}

export const paymentModeConstants = Object.freeze({
	WEEKLY: 'weekly',
	MONTHLY: 'monthly',
	QUARTERLY: 'quarterly',
	YEARLY: 'yearly',
});

export const paymentTypesConstants = Object.freeze({
	HOURLY: 'hourly',
	RETAINER: 'retainer',
});
export const paymentTermConstants = Object.freeze({
	DUE_ON_RECEIPT: 'due_on_receipt',
	NRT15: 'net 15',
	NET30: 'net 30',
	NET60: 'net 60',
});

export const clientPaymentModes = [
	{
		value: paymentModeConstants.WEEKLY,
		label: 'Weekly',
	},
	{
		value: paymentModeConstants.MONTHLY,
		label: 'Monthly',
	},

	{
		value: paymentModeConstants.QUARTERLY,
		label: 'Quarterly',
	},
	{
		value: paymentModeConstants.YEARLY,
		label: 'Yearly',
	},
];

export const clientPaymentTypes = [
	{
		value: paymentTypesConstants.HOURLY,
		label: 'Hourly',
	},
	{
		value: paymentTypesConstants.RETAINER,
		label: 'Retainer',
	},
];

export const clientPaymentTerms = [
	{
		value: paymentTermConstants.DUE_ON_RECEIPT,
		label: 'Due on Receipt',
	},
	{
		value: paymentTermConstants.NRT15,
		label: 'Net 15',
	},
	{
		value: paymentTermConstants.NET30,
		label: 'Net 30',
	},
	{
		value: paymentTermConstants.NET60,
		label: 'Net 60',
	},
];

export const draftInvoices = [
	{
		value: true,
		label: 'Enabled',
	},
	{
		value: false,
		label: 'Disabled',
	},
];

export const setWorkflowTasksOrder = (clientWorkflowTasks) => {
	let sortedArray = new Array();
	const originalArray = JSON.parse(JSON.stringify(clientWorkflowTasks));
	clientWorkflowTasks.forEach((thisTask) => {
		if (!clientWorkflowTasks?.find((_task) => _task.id === thisTask.dependent_task_id)) {
			thisTask.dependent_task_id = null;
		}
	});

	clientWorkflowTasks?.map((task) => {
		if (
			task?.dependent_task_id == null &&
			!clientWorkflowTasks?.find((item) => task?.id == item.dependent_task_id)
		) {
			sortedArray.push(task);
		}
	});
	// console.info('null sorted Array', new Array(...sortedArray));

	clientWorkflowTasks?.map((task) => {
		if (
			task?.dependent_task_id == null &&
			clientWorkflowTasks?.find((item) => task?.id == item.dependent_task_id)
		) {
			sortedArray.push(task);
			sortedArray.push(
				clientWorkflowTasks?.find((item) => task?.id == item.dependent_task_id),
			);
		}
	});
	// console.info('null 2 sorted Array', new Array(...sortedArray));

	clientWorkflowTasks?.map((task) => {
		const dependent_task = clientWorkflowTasks?.find(
			(item) => item.id == task?.dependent_task_id,
		);

		sortedArray = sortedArray?.filter((item) => item);

		const index_of_dependent_task = sortedArray?.findIndex(
			(item) => item.id == task?.dependent_task_id,
		);
		const index_of_task = sortedArray?.findIndex((item) => item.id == task?.id);

		if (
			task?.dependent_task_id != null &&
			clientWorkflowTasks?.some((item) => task?.id == item.dependent_task_id)
		) {
			if (
				!sortedArray?.find((item) => item.id == task?.id) &&
				!sortedArray?.find((item) => item.id == task?.dependent_task_id)
			) {
				sortedArray.push(dependent_task);
				sortedArray.push(task);
				// console.info('condition', new Array(...sortedArray));
			} else if (
				sortedArray?.find((item) => item.id == task?.id) &&
				sortedArray?.find((item) => item.id == task?.dependent_task_id)
			) {
				if (index_of_dependent_task < index_of_task) {
					sortedArray?.splice(index_of_task, 1);
					const new_index_of_dependent_task = sortedArray?.findIndex(
						(item) => item.id == task?.dependent_task_id,
					);
					sortedArray?.splice(new_index_of_dependent_task + 1, 0, task);
					// console.info('condition', new Array(...sortedArray));
				}
			} else if (
				!sortedArray?.find((item) => item.id == task?.id) &&
				sortedArray?.find((item) => item.id == task?.dependent_task_id)
			) {
				sortedArray.push(task);
				// console.info('condition', new Array(...sortedArray));
			} else if (
				sortedArray?.find((item) => item.id == task?.id) &&
				!sortedArray?.find((item) => item.id == task?.dependent_task_id)
			) {
				sortedArray.splice(index_of_task, 0, dependent_task);
				// console.info('condition', new Array(...sortedArray));
			}
		}
	});

	clientWorkflowTasks?.map((task) => {
		if (
			task?.dependent_task_id != null &&
			!clientWorkflowTasks?.some((item) => task?.id == item.dependent_task_id)
		) {
			const index_of_task = sortedArray?.findIndex((item) => item.id == task?.id);

			if (sortedArray?.find((item) => item.id == task?.id)) {
				sortedArray?.splice(index_of_task, 1);
				sortedArray?.push(task);
			} else {
				sortedArray?.push(task);
			}
		}
	});

	return (sortedArray = sortedArray
		?.filter((item) => item)
		?.map((item) => {
			return {
				...item,
				dependent_task_id: originalArray?.find((t) => t.id == item.id)?.dependent_task_id,
			};
		}));
};

export function sortTasks(workflowTasks) {
	const tasks = workflowTasks.map((item) => {
		if (item.id == item.dependent_task_id) {
			item.dependent_task_id = null;
		}
		return item;
	});

	const taskMap = new Map();
	for (const task of tasks) {
		taskMap.set(String(task.id), task);
	}

	const sortedTasks = [];

	tasks.sort((a, b) => {
		if (
			(a.dependent_task_id === null || a.dependent_task_id === 'null') &&
			b.dependent_task_id !== null &&
			b.dependent_task_id !== 'null'
		)
			return -1;
		if (
			a.dependent_task_id !== null &&
			a.dependent_task_id !== 'null' &&
			(b.dependent_task_id === null || b.dependent_task_id === 'null')
		)
			return 1;
		return 0;
	});

	for (const task of tasks) {
		dfs(task);
	}

	function dfs(task) {
		if (!task || sortedTasks.includes(task) || task.processing || !taskMap.has(String(task.id)))
			return;
		task.processing = true;

		if (
			task.dependent_task_id &&
			task.dependent_task_id !== null &&
			task.dependent_task_id !== 'null'
		) {
			const dependentTask = taskMap.get(String(task.dependent_task_id));
			if (dependentTask) {
				dfs(dependentTask);
			} else {
				dfs({ id: task.dependent_task_id, dependent_task_id: null });
			}
		}

		delete task.processing;
		sortedTasks.push(task);
	}

	return sortedTasks;
}

export function sortTemplateTasks(tasks) {
	// Create a map of tasks, with the task id as the key and the task as the value
	console.log(tasks, 'tasks132');
	const taskMap = new Map();
	for (const task of tasks) {
		taskMap.set(String(task.id), task);
	}

	const sortedTasks = [];
	const processedTasks = new Set();

	// This function performs a depth-first search on the task and adds it to the sortedTasks array
	function dfs(task) {
		// Check if the task is defined and has not already been processed
		if (!task || processedTasks.has(task)) return;

		// Mark the task as processed
		processedTasks.add(task);

		// If the task has a dependent task, process the dependent task first
		if (
			task.dependent_task_id &&
			task.dependent_task_id !== null &&
			task.dependent_task_id !== 'null'
		) {
			const dependentTask = taskMap.get(String(task.dependent_task_id));
			if (!processedTasks.has(dependentTask)) {
				dfs(dependentTask);
			}
		}

		// Add the task to the sortedTasks array
		sortedTasks.push(task);
	}

	// Sort the tasks so that tasks with a dependent_task_id of null or 'null' come first
	tasks.sort((a, b) => {
		if (
			(a.dependent_task_id === null || a.dependent_task_id === 'null') &&
			b.dependent_task_id !== null &&
			b.dependent_task_id !== 'null'
		)
			return -1;
		if (
			a.dependent_task_id !== null &&
			a.dependent_task_id !== 'null' &&
			(b.dependent_task_id === null || b.dependent_task_id === 'null')
		)
			return 1;
		return 0;
	});

	// Process the tasks in the sorted order
	for (const task of tasks) {
		dfs(task);
	}

	return sortedTasks;
}

export const workflowAccountsComponent = [
	'software-pick-list',
	'account-pick-list',
	'portal-pick-list',
	'merchant-pick-list',
	'card-pick-list',
];

export const getAccountComponentsWithSharedAccounts = (accountCategory) => {
	const helper = {
		[objectBeingDragged.SOFTWARE]: 'software-pick-list',
		[objectBeingDragged.BANK_ACCOUNT]: 'account-pick-list',
		[objectBeingDragged.PAYMENT_PORTAL]: 'portal-pick-list',
		[objectBeingDragged.MERCHANT_ACCOUNT]: 'merchant-pick-list',
		[objectBeingDragged.CREDIT_CARD_ACCOUNT]: 'card-pick-list',
	};
	return helper[accountCategory];
};

export function mapTasks(tasks = []) {
	if (!Array.isArray(tasks)) {
		return [];
	}

	const taskMap = {};
	const sortedTasks = tasks?.sort(
		(a, b) =>
			a.client_workflow_task?.dependency_order - b?.client_workflow_task?.dependency_order,
	);
	sortedTasks.forEach((task) => {
		// eslint-disable-next-line no-prototype-builtins
		if (!task.hasOwnProperty('workflow_recurrence_id')) {
			return [];
		}

		if (!taskMap[task.workflow_recurrence_id || 'nill']) {
			taskMap[task.workflow_recurrence_id || 'nill'] = [];
		}

		taskMap[task.workflow_recurrence_id || 'nill'].push(task);
	});

	const result = Object.values(taskMap).reduce((acc, taskList) => {
		for (let i = 0; i < taskList.length; i += 3) {
			acc.push(taskList.slice(i, i + 3));
		}
		return acc;
	}, []);

	return result;
}

export const REFERRAL_TYPE = Object.freeze({
	CLIENT: 'CLIENT',
	USER: 'USER',
	CONTACT: 'CONTACT',
	CUSTOM: 'CUSTOM',
});

export function modifyOptions(options) {
	const firstLetters = new Set(options.map((option) => option.label.charAt(0).toUpperCase()));
	const groupedOptions = options.reduce((acc, option) => {
		const firstLetter = option.label.charAt(0).toUpperCase();
		if (!acc[firstLetter]) {
			acc[firstLetter] = [{ label: firstLetter, value: null }];
		}
		acc[firstLetter].push(option);
		return acc;
	}, {});
	const filteredOptions = Object.entries(groupedOptions)
		.filter(([key]) => firstLetters.has(key))
		.map(([_, value]) => value)
		.flat()
		.sort((a, b) => a.label.localeCompare(b.label));
	return filteredOptions;
}

export const numberWithCommas = (x) => {
	const value = Number(x);
	if (value) {
		var parts = value?.toFixed(2).toString().split('.');
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		return parts.join('.');
	} else {
		return '0';
	}
};

export const statuses = [
	{
		img: CalendarIcon,
		text: 'In a meeting',
		time: '1 hour',
		unified: '1f5d3-fe0f',
		value: moment(moment().add(1, 'h').toDate()).format('YYYY-MM-DD h:mm A'),
	},
	{
		img: Car,
		text: 'Commuting',
		time: '30 minutes',
		unified: '1f68e',
		value: moment(moment().add(30, 'm').toDate()).format('YYYY-MM-DD h:mm A'),
	},
	{
		img: SmileyNervous,
		text: 'Out Sick',
		time: 'Today',
		unified: '1f912',
		value: moment(moment().add(1, 'd').toDate()).format('YYYY-MM-DD h:mm A'),
	},
	{
		img: Globe,
		text: 'Vacationing',
		time: 'This week',
		unified: '1f3dd-fe0f',
		value: moment(moment().add(7, 'd').toDate()).format('YYYY-MM-DD h:mm A'),
	},
	{
		img: House,
		text: 'Working remotely',
		time: 'Today',
		unified: '1f3e1',
		value: moment(moment().add(1, 'd').toDate()).format('YYYY-MM-DD h:mm A'),
	},
];

export const truncateString = (string, maxLength) => {
	const MAX_LENGTH = maxLength ? maxLength : 28;

	// Remove rich text formatting using regex
	const plainText = string.replace(/<[^>]+>/g, '');

	// Truncate the plain text if its length exceeds MAX_LENGTH
	const truncatedText =
		plainText.length > MAX_LENGTH ? plainText.substring(0, MAX_LENGTH) + '...' : plainText;

	return truncatedText;
};

export const timeRanges = [
	{ label: 'Custom', value: 'Custom' },
	{ label: 'This week', value: 'This week' },
	{ label: 'This month', value: 'This month' },
	{ label: 'This quarter', value: 'This quarter' },
	{ label: 'This year', value: 'This year' },
	{ label: 'Last week', value: 'Last week' },
	{ label: 'Last month', value: 'Last month' },
	{ label: 'Last quarter', value: 'Last quarter' },
	{ label: 'Last year', value: 'Last year' },
];

const getTimeRange = (start, end) => {
	start.set({
		hour: 0,
		minute: 0,
		second: 0,
		millisecond: 0,
	});
	end.set({
		hour: 23,
		minute: 59,
		second: 59,
		millisecond: 999,
	});
	return {
		from: start,
		to: end,
	};
};

export const getCurrentWeekRange = () => {
	const today = moment().startOf('day');
	const currentWeekStart = today.clone().startOf('week');
	const currentWeekEnd = currentWeekStart.clone().endOf('week');
	return getTimeRange(currentWeekStart, currentWeekEnd);
};

export const getCurrentMonthRange = () => {
	const today = moment().startOf('day');
	const currentMonthStart = today.clone().startOf('month');
	const currentMonthEnd = today.clone().endOf('month');
	return getTimeRange(currentMonthStart, currentMonthEnd);
};

export const getCurrentQuarterRange = () => {
	const today = moment().startOf('day');
	const currentQuarterStart = today.clone().startOf('quarter');
	const currentQuarterEnd = currentQuarterStart.clone().endOf('quarter');
	return getTimeRange(currentQuarterStart, currentQuarterEnd);
};

export const getCurrentYearRange = () => {
	const currentYearStart = moment().startOf('year');
	const currentYearEnd = moment().endOf('year');
	return getTimeRange(currentYearStart, currentYearEnd);
};

export const getLastWeekRange = () => {
	const today = moment().startOf('day');
	const lastWeekStart = today.clone().subtract(1, 'week').startOf('isoWeek');
	const lastWeekEnd = lastWeekStart.clone().endOf('isoWeek');
	return getTimeRange(lastWeekStart, lastWeekEnd);
};

export const getLastMonthRange = () => {
	const today = moment().startOf('day');
	const lastMonthStart = today.clone().subtract(1, 'month').startOf('month');
	const lastMonthEnd = lastMonthStart.clone().endOf('month');
	return getTimeRange(lastMonthStart, lastMonthEnd);
};

export const getLastQuarterRange = () => {
	const today = moment().startOf('day');
	const lastQuarterStart = today.clone().subtract(1, 'quarter').startOf('quarter');
	const lastQuarterEnd = lastQuarterStart.clone().endOf('quarter');
	return getTimeRange(lastQuarterStart, lastQuarterEnd);
};

export const getLastYearRange = () => {
	const lastYearStart = moment().subtract(1, 'year').startOf('year');
	const lastYearEnd = moment().subtract(1, 'year').endOf('year');
	return getTimeRange(lastYearStart, lastYearEnd);
};

export const roundUpConsumedTimeInDecimal = (number) => {
	const roundedUp = parseFloat(number).toFixed('2');
	return roundedUp;
};

export const convertDecimalToTime = (decimalNumber = 0) => {
	const isNegative = decimalNumber < 0;
	const absoluteValue = Math.abs(decimalNumber);
	const hours = Math.floor(absoluteValue);
	const minutes = Math.floor((absoluteValue - hours) * 60);
	const seconds = Math.floor(((Math.abs(absoluteValue) - hours) * 60 - minutes) * 60);

	let roundedMinutes = minutes;
	let roundedHours = hours;
	if (minutes >= 1 && seconds >= 30) {
		roundedMinutes += 1;
		if (roundedMinutes == 60) {
			roundedMinutes = 0;
			roundedHours += 1;
		}
	}

	const formattedHours = String(roundedHours).padStart(2, '0');
	const formattedMinutes = String(roundedMinutes).padStart(2, '0');

	if (isNegative) return `-${formattedHours} : ${formattedMinutes}`;
	else return formattedHours + ' : ' + formattedMinutes;
};

export const convertToDecimal = (time) => {
	const [hours, minutes] = time.split(':').map(Number);
	return hours + minutes / 60;
};

export const dependentTaskCompleteAlert = (userA, userB, task_title) => {
	let header = '';
	let body = '';
	if (userA == userB) {
		header = 'Completing this task requires the preceding task to also be complete';
		body = `This task depends on the completion of the preceding task, 
				${task_title}. Return to that task and complete it before proceeding to this task.`;
	} else {
		header = 'Contact your team member to complete the preceding task this task depends on';
		body = `This task depends on the completion of the preceding task, ${task_title}, that is assigned to another team member. Notify your team member to mark it as complete by mentioning your team member in the task comment.`;
	}

	return { header, body };
};
export const ERROR_CODE = {
	START_DATE_BEFORE_PRECEEDING_TASK_SAME_ASSIGNEE:
		'START_DATE_BEFORE_PRECEEDING_TASK_SAME_ASSIGNEE',
	START_DATE_BEFORE_PRECEEDING_TASK_DIFFERENT_ASSIGNEE:
		'START_DATE_BEFORE_PRECEEDING_TASK_DIFFERENT_ASSIGNEE',
	MOVED_BY_MANAGER_BEFORE_START_DATE: 'MOVED_BY_MANAGER_BEFORE_START_DATE',
	MOVED_BY_MANAGER_AFTER_DUE_DATE: 'MOVED_BY_MANAGER_AFTER_DUE_DATE',
	MOVED_BY_MEMBER: 'MOVED_BY_MEMBER',
	TASK_NOT_DRAGGABLE_NEXT_RECURRENCE: 'TASK_NOT_DRAGGABLE_NEXT_RECURRENCE',
	TASK_NOT_DRAGGABLE_PREV_RECURRENCE: 'TASK_NOT_DRAGGABLE_PREV_RECURRENCE',
};
export const taskDragAlertContent = (error_code) => {
	let header = '';
	let doneButtonText = '';
	let cancelButtonText = '';
	if (error_code == ERROR_CODE.START_DATE_BEFORE_PRECEEDING_TASK_SAME_ASSIGNEE) {
		header = 'Completing this task requires the preceding task to also be complete';
		doneButtonText = 'Go To Preceeding Task';
		cancelButtonText = 'Cancel';
	}
	if (error_code == ERROR_CODE.START_DATE_BEFORE_PRECEEDING_TASK_DIFFERENT_ASSIGNEE) {
		header = 'Contact your team member to complete the preceding task this task depends on';
		doneButtonText = 'Go To Preceeding Task';
		cancelButtonText = 'Cancel';
	}
	if (error_code == ERROR_CODE.MOVED_BY_MANAGER_BEFORE_START_DATE) {
		header = 'Are you sure you want to start the task earlier?';
		doneButtonText = 'Yes, start this task earlier';
		cancelButtonText = 'Cancel';
	}
	if (error_code == ERROR_CODE.MOVED_BY_MANAGER_AFTER_DUE_DATE) {
		header = 'Are you sure you want to extend the due date?';
		doneButtonText = 'Extend due date';
		cancelButtonText = 'Cancel';
	}
	if (error_code == ERROR_CODE.MOVED_BY_MEMBER) {
		header = 'Contact your manager to change the dates';
		doneButtonText = '';
		cancelButtonText = 'Close';
	}
	// if (error_code == ERROR_CODE.TASK_NOT_DRAGGABLE_NEXT_RECURRENCE) {
	// 	header = 'The next recurrence of the workflow exists on that date';
	// 	cancelButtonText = 'Close';
	// }
	// if (error_code == ERROR_CODE.TASK_NOT_DRAGGABLE_PREV_RECURRENCE) {
	// 	header = 'The previous recurrence of the workflow exists on that date';
	// 	cancelButtonText = 'Close';
	// }
	return { header, doneButtonText, cancelButtonText };
};

export const resourceslist = ['Resources', 'Notes'];

export const builderSideMenu = [
	{
		id: 1,
		name: 'Published',
	},
	{
		id: 2,
		name: 'Draft',
	},
];

export function formatBytes(bytes) {
	if (bytes === 0) return '0 Bytes';
	const k = 1024;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = parseInt(Math.floor(Math.log(bytes) / Math.log(k)));

	return (bytes / Math.pow(k, i)).toFixed(1) + ' ' + sizes[i];
}
export const toLocalTz = (date) => {
	return moment.tz(date, moment.tz.guess());
};

export const updateParentFolderIds = (data) => {
	const ids = data?.map((item) => item.id);

	return data?.map((item) => {
		if (!ids.includes(item.parent_folder_id)) {
			return { ...item, parent_folder_id: null };
		}
		return { ...item };
	});
};

export const sortFolderData = (folderData) => {
	folderData?.sort((a, b) => {
		const isAFile = a.kind === 'File';
		const isBFile = b.kind === 'File';

		if (isAFile && !isBFile) {
			return -1;
		}
		if (!isAFile && isBFile) {
			return 1;
		}
		return 0;
	});

	return folderData;
};

export const filterFolderData = (folderData) => {
	return folderData?.filter(
		(folder) =>
			folder?.access_control?.role_level === Member.teamMemberId ||
			folder?.access_control?.role_level === Member.externalCollaborator,
	);
};

export const removeDuplicateFolders = (folderData) => {
	return folderData?.filter((folder, index, self) => {
		return index === self.findIndex((f) => f.id === folder.id);
	});
};

export const countryStatesDummy = [
	{
		label: 'United States',
		value: 'United States',
		abbr: 'US',
		currency: 'USD',
		symbol: '$',
		states: [
			{ label: 'Alabama', value: 'Alabama' },
			{ label: 'Alaska', value: 'Alaska' },
			{ label: 'Arizona', value: 'Arizona' },
			{ label: 'Arkansas', value: 'Arkansas' },
			{ label: 'California', value: 'California' },
			{ label: 'Colorado', value: 'Colorado' },
			{ label: 'Connecticut', value: 'Connecticut' },
			{ label: 'Delaware', value: 'Delaware' },
			{ label: 'Florida', value: 'Florida' },
			{ label: 'Georgia', value: 'Georgia' },
			{ label: 'Hawaii', value: 'Hawaii' },
			{ label: 'Idaho', value: 'Idaho' },
			{ label: 'Illinois', value: 'Illinois' },
			{ label: 'Indiana', value: 'Indiana' },
			{ label: 'Iowa', value: 'Iowa' },
			{ label: 'Kansas', value: 'Kansas' },
			{ label: 'Kentucky', value: 'Kentucky' },
			{ label: 'Louisiana', value: 'Louisiana' },
			{ label: 'Maine', value: 'Maine' },
			{ label: 'Maryland', value: 'Maryland' },
			{ label: 'Massachusetts', value: 'Massachusetts' },
			{ label: 'Michigan', value: 'Michigan' },
			{ label: 'Minnesota', value: 'Minnesota' },
			{ label: 'Mississippi', value: 'Mississippi' },
			{ label: 'Missouri', value: 'Missouri' },
			{ label: 'Montana', value: 'Montana' },
			{ label: 'Nebraska', value: 'Nebraska' },
			{ label: 'Nevada', value: 'Nevada' },
			{ label: 'New Hampshire', value: 'New Hampshire' },
			{ label: 'New Jersey', value: 'New Jersey' },
			{ label: 'New Mexico', value: 'New Mexico' },
			{ label: 'New York', value: 'New York' },
			{ label: 'North Carolina', value: 'North Carolina' },
			{ label: 'North Dakota', value: 'North Dakota' },
			{ label: 'Ohio', value: 'Ohio' },
			{ label: 'Oklahoma', value: 'Oklahoma' },
			{ label: 'Oregon', value: 'Oregon' },
			{ label: 'Pennsylvania', value: 'Pennsylvania' },
			{ label: 'Rhode Island', value: 'Rhode Island' },
			{ label: 'South Carolina', value: 'South Carolina' },
			{ label: 'South Dakota', value: 'South Dakota' },
			{ label: 'Tennessee', value: 'Tennessee' },
			{ label: 'Texas', value: 'Texas' },
			{ label: 'Utah', value: 'Utah' },
			{ label: 'Vermont', value: 'Vermont' },
			{ label: 'Virginia', value: 'Virginia' },
			{ label: 'Washington', value: 'Washington' },
			{ label: 'West Virginia', value: 'West Virginia' },
			{ label: 'Wisconsin', value: 'Wisconsin' },
			{ label: 'Wyoming', value: 'Wyoming' },
		],
	},
	{
		label: 'Australia',
		value: 'Australia',
		abbr: 'AU',
		currency: 'AUD',
		symbol: '$',
		states: [
			{ label: 'Australian Capital Territory', value: 'Australian Capital Territory' },
			{ label: 'New South Wales', value: 'New South Wales' },
			{ label: 'Northern Territory', value: 'Northern Territory' },
			{ label: 'Queensland', value: 'Queensland' },
			{ label: 'South Australia', value: 'South Australia' },
			{ label: 'Tasmania', value: 'Tasmania' },
			{ label: 'Victoria', value: 'Victoria' },
			{ label: 'Western Australia', value: 'Western Australia' },
		],
	},
	{
		label: 'United Kingdom',
		value: 'United Kingdom',
		abbr: 'UK',
		currency: 'GBP',
		symbol: '£',
		states: [
			{ label: 'England', value: 'England' },
			{ label: 'Scotland', value: 'Scotland' },
			{ label: 'Wales', value: 'Wales' },
			{ label: 'Northern Ireland', value: 'Northern Ireland' },
		],
	},
	{
		label: 'Canada',
		value: 'Canada',
		abbr: 'CA',
		currency: 'CAD',
		symbol: '$',
		states: [
			{ label: 'Alberta', value: 'Alberta' },
			{ label: 'British Columbia', value: 'British Columbia' },
			{ label: 'Manitoba', value: 'Manitoba' },
			{ label: 'New Brunswick', value: 'New Brunswick' },
			{ label: 'Newfoundland and Labrador', value: 'Newfoundland and Labrador' },
			{ label: 'Northwest Territories', value: 'Northwest Territories' },
			{ label: 'Nova Scotia', value: 'Nova Scotia' },
			{ label: 'Nunavut', value: 'Nunavut' },
			{ label: 'Ontario', value: 'Ontario' },
			{ label: 'Prince Edward Island', value: 'Prince Edward Island' },
			{ label: 'Quebec', value: 'Quebec' },
			{ label: 'Saskatchewan', value: 'Saskatchewan' },
			{ label: 'Yukon', value: 'Yukon' },
		],
	},
];

export const countriesCurrency = [
	{
		label: 'USD',
		value: 'USD',
	},
	{
		label: 'AUD',
		value: 'AUD',
	},
	{
		label: 'GBP',
		value: 'GBP',
	},
	{
		label: 'CAD',
		value: 'CAD',
	},
];

export const SERVER_SENT_EVENTS = Object.freeze({
	TASK_GET: 'task_get',
	TASK_CREATED: 'task_created',
	TASK_UPDATED: 'task_updated',
	TASK_DELETED: 'task_deleted',
	TASK_ASSIGNEE_UPDATED: 'task_assignee_updated',
	TASK_ASSIGNEE_DELETED: 'task_assignee_deleted',
	//for comments
	COMMENT_CREATED: 'comment_created',
	//for client workflow
	CLIENT_WORKFLOW_CREATED: 'client_workflow_created',
	CLIENT_WORKFLOW_UPDATED: 'client_workflow_updated',
	CLIENT_WORKFLOW_DELETED: 'client_workflow_deleted',
	//loggers
	STOP_TIME: 'stop_time',
	TASK_ACTIVITIES: 'task_activities',
	//workspace_update
	WORKSPACE_UPDATED: 'WORKSPACE_UPDATED',
	TASK_STATUS_UPDATED: 'task_status_updated',
	USER_ACTIVITY: 'user_activity',
	TASK_TIMER_UPDATED: 'task_timer_updated',
});

export const DATE_FORMATS = {
	'MM/DD/YYYY': { full: 'MM/DD/YYYY', short: 'MM/DD', full_month_name: 'MMMM Do YYYY' },
	'DD/MM/YYYY': { full: 'DD/MM/YYYY', short: 'DD/MM', full_month_name: 'Do MMMM YYYY' },
};

export const DATE_FORMATS_OPTIONS = [
	{ value: 'MM/DD/YYYY', label: 'MM/DD/YYYY' },
	{ value: 'DD/MM/YYYY', label: 'DD/MM/YYYY' },
];

export const dateDifference = (date1, date2) => {
	const momentDate1 = moment(date1);
	const momentDate2 = moment(date2);

	const differenceInDays = momentDate1.diff(momentDate2, 'days');

	return Math.abs(differenceInDays);
};
export function countWeekdays(startDate, endDate) {
	const start = moment(startDate).startOf('day');
	const end = moment(endDate).startOf('day');
	let weekdaysCount = 0;

	while (start.isSameOrBefore(end)) {
		if (start.day() >= 1 && start.day() <= 5) {
			// Monday = 1, Friday = 5
			weekdaysCount++;
		}
		start.add(1, 'day');
	}

	return weekdaysCount;
}

export const calculateDueDate = (paymentTermValue, currentDate) => {
	let daysToAdd = 0;

	switch (paymentTermValue) {
		case paymentTermConstants.DUE_ON_RECEIPT:
			daysToAdd = 0;
			break;
		case paymentTermConstants.NRT15:
			daysToAdd = 15;
			break;
		case paymentTermConstants.NET30:
			daysToAdd = 30;
			break;
		case paymentTermConstants.NET60:
			daysToAdd = 60;
			break;
		default:
			break;
	}

	return moment(currentDate).add(daysToAdd, 'days').format('MM/DD/yyyy');
};

export const calculatePaymentTerm = (issueDate, dueDate) => {
	const daysDifference = moment(moment(dueDate).format('MM/DD/yyyy')).diff(
		moment(issueDate).format('MM/DD/yyyy'),
		'days',
	);

	if (daysDifference === 0) return paymentTermConstants.DUE_ON_RECEIPT;
	if (daysDifference === 15) return paymentTermConstants.NRT15;
	if (daysDifference === 30) return paymentTermConstants.NET30;
	if (daysDifference === 60) return paymentTermConstants.NET60;

	return null;
};

export const formatRateValue = (value) => {
	const trimmedValue = value.replace(/[^0-9.]/g, '');
	const withoutDollarSign = trimmedValue.startsWith('$') ? trimmedValue.slice(1) : trimmedValue;

	const hasDecimal = withoutDollarSign.includes('.');
	const parsedValue = parseFloat(withoutDollarSign.replace(/,/g, ''));

	let formattedValue;
	if (!isNaN(parsedValue)) {
		if (hasDecimal) {
			const decimalPart = withoutDollarSign.split('.')[1];
			formattedValue =
				decimalPart.length <= 2
					? withoutDollarSign.replace(/^\s+/, '')
					: parsedValue.toFixed(2).replace(/^\s+/, '');
		} else {
			formattedValue = parsedValue
				.toLocaleString('en-US', {
					minimumFractionDigits: 0,
					maximumFractionDigits: 2,
				})
				.replace(/^\s+/, '');
		}
	} else {
		formattedValue = withoutDollarSign.replace(/^\s+/, '');
	}

	return formattedValue;
};

export const invoiceStatusConstants = Object.freeze({
	DRAFT: 'Draft',
	SENT: 'Sent',
	VOID: 'Void',
});

export const invoices_status = [
	{ label: 'Draft', value: invoiceStatusConstants.DRAFT },
	{ label: 'Sent', value: invoiceStatusConstants.SENT },
	{ label: 'Void', value: invoiceStatusConstants.VOID },
];

export const type_options = [
	{ label: 'Automated', value: 'automatic' },
	{ label: 'Manual', value: 'manual' },
];

export const PLATFORM_KEYS = { QBO: 'ndsk' };

export const buildQueryString = (filters) => {
	const query = Object.entries(filters)
		.map(([key, value]) => {
			if (Array.isArray(value)) {
				return value
					.map((item) => `${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`)
					.join('&');
			} else if (value !== undefined && value !== null) {
				return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
			} else {
				return '';
			}
		})
		.filter((part) => part.length > 0)
		.join('&');
	return query;
};

export const dateRanges = [
	{
		label: 'Today',
		value: { start_date: moment().startOf('day'), end_date: moment().endOf('day') },
	},
	{
		label: 'Yesterday',
		value: {
			start_date: moment().subtract(1, 'days').startOf('day'),
			end_date: moment().subtract(1, 'days').endOf('day'),
		},
	},
	{
		label: 'This week',
		value: { start_date: moment().startOf('week'), end_date: moment().endOf('week') },
	},
	{
		label: 'Last week',
		value: {
			start_date: moment().subtract(1, 'weeks').startOf('week'),
			end_date: moment().subtract(1, 'weeks').endOf('week'),
		},
	},
	{
		label: 'This month',
		value: { start_date: moment().startOf('month'), end_date: moment().endOf('month') },
	},
	{
		label: 'Last month',
		value: {
			start_date: moment().subtract(1, 'months').startOf('month'),
			end_date: moment().subtract(1, 'months').endOf('month'),
		},
	},
	{
		label: 'This quarter',
		value: { start_date: moment().startOf('quarter'), end_date: moment().endOf('quarter') },
	},
	{
		label: 'Last quarter',
		value: {
			start_date: moment().subtract(1, 'quarters').startOf('quarter'),
			end_date: moment().subtract(1, 'quarters').endOf('quarter'),
		},
	},
	{
		label: 'Last 6 months',
		value: {
			start_date: moment().subtract(6, 'months').startOf('month'),
			end_date: moment().endOf('month'),
		},
	},
	{
		label: 'Last 12 months',
		value: {
			start_date: moment().subtract(12, 'months').startOf('month'),
			end_date: moment().endOf('month'),
		},
	},
];

export function filterNullAttributes(obj = {}) {
	return Object.fromEntries(Object.entries(obj).filter(([key, value]) => value != null));
}
export const generateStartInvoiceNumber = (clientId) => {
	const clientIdStr = clientId.toString();
	const padding = '0000';
	const paddedClientId = padding.substring(0, padding.length - clientIdStr.length) + clientIdStr;
	return paddedClientId;
};

export const DOCUMENT_INVITE_STATUSES = {
	PENDING: 'Pending',
	SUBMITTED: 'Submitted',
	EXPIRED: 'Expired',
	FULFILED: 'Fulfilled',
};

export const generateUniqueFiles = (files) => {
	return files.map((file) => {
		const timestamp = moment().format().replace('T', '_');
		const fileNameParts = file.name.split('.');

		// Check if the file has an extension
		if (fileNameParts.length > 1) {
			const fileName = `${fileNameParts
				.slice(0, -1)
				.join('.')}_${timestamp}.${fileNameParts.pop()}`;
			return new File([file], fileName);
		} else {
			const fileName = `${file.name}_${timestamp}`;
			return new File([file], fileName);
		}
	});
};

export const DATE_RANGE_FILTER = {
	IS_ON: 'is_on',
	IS_BETWEEN: 'is_between',
	IS_AFTER: 'is_after',
};
export function splitName(fullName) {
	const nameParts = fullName.trim().split(' ');

	const hasOnlyFirstName = nameParts.length === 1;
	if (hasOnlyFirstName) return { firstName: nameParts[0], lastName: '' };

	const firstName = nameParts[0];
	const lastName = nameParts.slice(1).join(' ');

	return { firstName, lastName };
}

export const emptyFunction = () => {
	return;
};

export const ACTION_SIDE_BAR_COMPONENT_KEY = {
	REASSIGN: 'REASSIGN',
	RESCHEDULE: 'RESCHEDULE',
};

export const calculateEndBillingDate = (nextBillingDate, paymentMode) => {
	if (!nextBillingDate || !paymentMode) return null;

	const nextDate = moment(nextBillingDate);
	switch (paymentMode) {
		case paymentModeConstants.WEEKLY:
			return nextDate.add(1, 'week').subtract(1, 'day').toDate();
		case paymentModeConstants.MONTHLY:
			return nextDate.add(1, 'month').subtract(1, 'day').toDate();
		case paymentModeConstants.QUARTERLY:
			return nextDate.add(1, 'quarter').subtract(1, 'day').toDate();
		case paymentModeConstants.YEARLY:
			return nextDate.add(1, 'year').subtract(1, 'day').toDate();
		default:
			return null;
	}
};
// saved filters structure
// const filters = {
// 	2(userId): [
// 		{
// 			id: 3(unique),
// 			name: 'any',
// 			filters: {
// 				...filters
// 			},
// 		},
// 	],
// 	3(userId): [
// 		{
// 			id: 2(unique),
// 			name: 'any',
// 			filters: {
// 				...filters,
// 			},
// 		},
// 	],
// };
