/* eslint-disable no-mixed-spaces-and-tabs */
import moment from 'moment';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import SkeletonLoading from '../Others/SkeletonLoading';

function MonthlyViewDateCards({
	monthlyDate,
	weekends,
	weekdays,
	handleDragOver,
	handleDragLeave,
	handleSideBar,
	draggingTaskDetails,
	dragOverContainer,
	selectedDate,
	viewSideBar,
}) {
	const monthlyTasksCountList = useSelector(
		(state) => state.workSpaceReducer.monthlyTasksCountList,
	);
	const monthlyTasksCountLoading = useSelector(
		(state) => state.workSpaceReducer.monthlyTasksCountLoading,
	);

	const keyMapping = useMemo(() => {
		return monthlyTasksCountList?.reduce((acc, item) => {
			const key = item.date;
			if (key) {
				acc[key] = item;
			}
			return acc;
		}, {});
	}, [monthlyTasksCountList]);

	const weeks = Array.from(
		{
			length: monthlyDate?.endOfWeek?.diff(monthlyDate?.startOfWeek, 'weeks') + 1,
		},
		(_, i) => {
			const currentDay = monthlyDate?.startOfWeek.clone().add(i, 'weeks');
			return (
				<div
					key={currentDay.format()}
					className='d-flex flex-wrap'
					style={{
						height: '169.6px',
						width: '100%',
						borderRadius: '4px',
						borderTopRightRadius: '4px',
						borderTopLeftRadius: '4px',
						background: '#FCFDFD',
						marginBottom: '20px',
						paddingBottom: '12px',
						marginTop: i === 0 ? '20px' : '0px',
					}}
				>
					{Array.from({ length: weekends ? 7 : 5 }, (_, j) => {
						const date = currentDay.clone().add(j, 'days');
						const formatedDate = date?.format('YYYY-MM-DD');
						const { client_count, task_count } = keyMapping[formatedDate] || {};

						return (
							<div
								key={date.format()}
								className='flex-grow-0 text-center d-flex align-items-center justify-content-center'
								style={{
									width: `${100 / weekdays?.length}%`,
									padding: '10px',
								}}
							>
								<div
									className='w-100 h-100 d-flex justify-content-center align-items-center'
									onDragOver={(e) => handleDragOver(e, date.format())}
									onDragLeave={handleDragLeave}
								>
									<SkeletonLoading
										loading={monthlyTasksCountLoading}
										variant='text'
										width={100}
										height={150}
									>
										<div
											className='d-flex flex-column align-items-center justify-content-center'
											onClick={() => handleSideBar(date)}
											style={{
												backgroundColor: `${
													draggingTaskDetails &&
													moment(dragOverContainer)?.isSame(date, 'day')
														? '#FB7A03'
														: date.diff(
																moment(
																	draggingTaskDetails?.start_date,
																),
														  ) >= 0 &&
														  date.diff(
																moment(
																	draggingTaskDetails?.due_date,
																),
														  ) <= 0
														? '#A3E8D1'
														: date?.format('MMMM D, YYYY') ==
																selectedDate?.format(
																	'MMMM D, YYYY',
																) && viewSideBar
														? '#FB7A03'
														: '#F7F9FA'
												}`,
												color: `${
													moment(dragOverContainer)?.isSame(date, 'day')
														? '#ffffff'
														: date?.format('MMMM D, YYYY') ==
																selectedDate?.format(
																	'MMMM D, YYYY',
																) && viewSideBar
														? '#ffffff'
														: '#000000'
												}`,
												border: date.isSame(moment(), 'day')
													? '1px solid #FB7A03'
													: 'none',
												height: '100px',
												width: '100px',
												boxShadow: '0px 7px 29px rgba(100, 100, 111, 0.2)',
												borderRadius: '4px',
												cursor: 'pointer',
											}}
										>
											<div
												className={`${
													!date.isSame(monthlyDate?.startOfMonth, 'month')
														? 'text-muted BodyOneBold'
														: 'BodyOneBold'
												}`}
												style={{
													color: `${
														moment(dragOverContainer)?.isSame(
															date,
															'day',
														)
															? '#ffffff'
															: date?.format('MMMM D, YYYY') ==
																	selectedDate?.format(
																		'MMMM D, YYYY',
																	) && viewSideBar
															? '#ffffff'
															: ''
													}`,
												}}
											>
												{date.format('MMM DD, YY')}
											</div>
											<div
												className={`${
													!date.isSame(monthlyDate?.startOfMonth, 'month')
														? 'text-muted mt-2 BodyOneLight'
														: 'mt-2 BodyOneLight'
												}`}
												style={{
													color: `${
														moment(dragOverContainer)?.isSame(
															date,
															'day',
														)
															? '#ffffff'
															: date?.format('MMMM D, YYYY') ==
																	selectedDate?.format(
																		'MMMM D, YYYY',
																	) && viewSideBar
															? '#ffffff'
															: '#696F6C'
													}`,
												}}
											>
												Tasks: {task_count}
											</div>
											<div
												className={`${
													!date.isSame(monthlyDate?.startOfMonth, 'month')
														? 'text-muted BodyOneLight'
														: 'BodyOneLight'
												}`}
												style={{
													color: `${
														dragOverContainer == date?.format()
															? '#ffffff'
															: date?.format('MMMM D, YYYY') ==
																	selectedDate?.format(
																		'MMMM D, YYYY',
																	) && viewSideBar
															? '#ffffff'
															: '#696F6C'
													}`,
												}}
											>
												Clients: {client_count}
											</div>
										</div>
									</SkeletonLoading>
								</div>
							</div>
						);
					})}
				</div>
			);
		},
	);

	return weeks;
}

export default memo(MonthlyViewDateCards);
