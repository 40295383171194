import React, { useState } from 'react';
import SubmitButtonsField from '../Buttons/SubmitButtonsField';

function EditWorkflowBuilderName({ onSubmit, name, closeModal }) {
	const [workflowName, setWorkflowName] = useState(name);

	const [loading, setLoading] = useState(false);

	const updateName = async () => {
		setLoading(true);
		await onSubmit({ name: workflowName.trim() });
		setLoading(false);
        closeModal();
	};

	return (
		<div>
			<div className='VerticalSpacer'>
				<div className='improved-input-designs p-3'>
					<label>Workflow Name</label>
					<input
						className='w-100'
						type='text'
						value={workflowName}
						name='name'
						onChange={(e) => setWorkflowName(e.target.value)}
					/>
				</div>

				<SubmitButtonsField
					rootClass='bg-white p-3'
					title={'Save'}
					onCancel={closeModal}
					onSubmit={updateName}
					isLoading={loading}
				/>
			</div>
		</div>
	);
}

export default EditWorkflowBuilderName;
