import { useState } from 'react';
import { ReactComponent as ClipBoardIcon } from '../../assets/icons/ClipboardText.svg';
import WorkflowTaskCard from '../sub_components/WorkflowTaskCard';

export default function NewTaskSections({ tasks }) {
	const [allExpand, setAllExpand] = useState(true);
	const [expandedStates, setExpandedStates] = useState(new Array(tasks?.length).fill(true));

	const toggleExpandAll = () => {
		setAllExpand(!allExpand);
		setExpandedStates(new Array(tasks.length).fill(!allExpand));
	};

	const handleExpandChange = (index, isExpanded) => {
		const updatedExpandedStates = [...expandedStates];
		updatedExpandedStates[index] = isExpanded;

		setExpandedStates(updatedExpandedStates);

		const allExpanded = updatedExpandedStates.every((state) => state);
		const allCollapsed = updatedExpandedStates.every((state) => !state);

		if (allExpanded) {
			setAllExpand(true);
		}
		if (allCollapsed) {
			setAllExpand(false);
		}
	};

	return (
		<div>
			<div className='icon-header justify-content-between' style={{ marginBottom: '18px' }}>
				<div className='d-flex align-items-center'>
					<div style={{ marginRight: '12px' }}>
						<ClipBoardIcon />
					</div>
					<span className='HeadlineThreeBold mt-1'>Workflow Tasks</span>
				</div>
				<span className='BodyTwoLight primary-text mt-1 pointer' onClick={toggleExpandAll}>
					{allExpand ? 'Collapse Tasks' : 'Expand Tasks'}
				</span>
			</div>
			{tasks?.length > 0 ? (
				tasks?.map((task, index) => {
					const dependencyTitle =
						tasks.find((t) => t.id === task?.dependent_task_id)?.title || 'None';
					return (
						<WorkflowTaskCard
							key={task?.id}
							task={task}
							title={task?.title}
							budgetedHours={task.budgeted_hours}
							description={task?.description}
							dependencyOrder={`${parseInt(task?.dependency_order) + 1}/${
								tasks?.length
							}`}
							dependencyTitle={dependencyTitle}
							index={index}
							allExpand={allExpand}
							handleExpandChange={handleExpandChange}
						/>
					);
				})
			) : (
				<div className='workflow-bar d-flex justify-content-center mt-4'>
					<span className='BodyTwoLight' style={{ color: '#696F6C' }}>
						No Task in this Workflow
					</span>
				</div>
			)}
		</div>
	);
}
