import { CircularProgress, Modal } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import TasksComponent from '../../sub_components/TasksComponent';
import { useDispatch, useSelector } from 'react-redux';
import AddTemplateTask from '../Modals/AddTemplateTask';
import DummyModal from '../../Modal/DummyModal';
import useModal from '../../Modal/useModal';
import * as builderActions from '../../../redux/actions/workflow-builder-actions';
import { ReactComponent as Ellipse } from '../../../assets/icons/Ellipse.svg';
import AddIcon from '@mui/icons-material/Add';
import TaskAlert from '../../Alerts/TaskAlert';
import { setSnackBarIsOpen } from '../../../redux/actions/dashboard/workspaces-actions';
import { useParams } from 'react-router';

function WorkflowBuilderTasks() {
	const dispatch = useDispatch();
	const { id } = useParams();

	const [formProp, setFormProp] = useState({});
	const [expandTasks, setExpandTasks] = useState(true);
	const [expandedStates, setExpandedStates] = useState([]);
	const [isAlert, setIsAlert] = useState(false);
	const loading = useSelector((state) => state.WorkflowBuilder.loading);
	const [tasks, setTasks] = useState([]);
	const [previousOrder, setPreviousOrder] = useState([]);
	const [updateTaskOrderLoading, setUpdateTaskOrderLoading] = useState(false);
	const [draggedTask, setDraggedTask] = useState(null);
	const dragOverRef = useRef(null);

	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

	const task_templates = useSelector((state) => state.WorkflowBuilder.task_templates);
	const sortedTasks = useMemo(() => {
		return task_templates?.sort((a, b) => a.dependency_order - b.dependency_order);
	}, [task_templates]);

	const toggleExpandAll = () => {
		setExpandTasks(!expandTasks);
		setExpandedStates(new Array(tasks.length).fill(!expandTasks));
	};

	const handleExpandChange = (index, isExpanded) => {
		const updatedExpandedStates = [...expandedStates];
		updatedExpandedStates[index] = isExpanded;

		setExpandedStates(updatedExpandedStates);

		const allExpanded = updatedExpandedStates.every((state) => state);
		const allCollapsed = updatedExpandedStates.every((state) => !state);

		if (allExpanded) {
			setExpandTasks(true);
		}
		if (allCollapsed) {
			setExpandTasks(false);
		}
	};

	const addNewTaskRow = () => {
		ModalType('TEMPLATE_TASK');
		dispatch(builderActions.clearWorkflowTaskValues());
		toggleModal();
		setFormProp({});
	};

	const addTaskTemplate = () => {
		toggleModal();
		setFormProp({});
		dispatch(builderActions.getTaskTemplates(id));

	};

	const handleDragStart = (event, task) => {
		setDraggedTask(task);
		event.dataTransfer.effectAllowed = 'move';
	};

	const handleDragOver = (e, dragOverTask) => {
		e.preventDefault();

		if (!draggedTask || !dragOverTask || draggedTask.id === dragOverTask.id) return;

		const draggedIndex = tasks.findIndex((task) => task.id === draggedTask.id);
		const targetIndex = tasks.findIndex((task) => task.id === dragOverTask.id);

		if (draggedIndex === -1 || targetIndex === -1 || draggedIndex === targetIndex) return;

		const updatedTasks = [...tasks];
		const [draggedItem] = updatedTasks.splice(draggedIndex, 1);
		updatedTasks.splice(targetIndex, 0, draggedItem);

		setTasks(
			updatedTasks?.map((task, index) => {
				return {
					...task,
					dependency_order: Number(index),
				};
			}),
		);

		dragOverRef.current = dragOverTask;
	};

	const handleDragEnd = () => {
		setDraggedTask(null);

		if (!draggedTask || !dragOverRef.current) {
			setTasks(previousOrder);
			return;
		}

		const draggedIndex = tasks.findIndex((task) => task.id === draggedTask.id);

		if (draggedIndex === draggedTask?.dependency_order) {
			return;
		}

		const parentTask = tasks.find((task) => task.id === draggedTask.dependent_task_id);
		const childTasks = tasks.filter((task) => task.dependent_task_id === draggedTask.id);
		const parentIndex = parentTask ? tasks.findIndex((task) => task.id === parentTask.id) : -1;

		if (parentIndex > draggedIndex) {
			setTasks(previousOrder);
			setIsAlert(true);
			return;
		}

		for (const child of childTasks) {
			const childIndex = tasks.findIndex((task) => task.id === child.id);
			if (childIndex < draggedIndex) {
				setTasks(previousOrder);
				setIsAlert(true);
				return;
			}
		}

		setUpdateTaskOrderLoading(true);
		dispatch(
			builderActions.updateWorkflowTasksDependecyOrder({
				tasks: tasks?.map((task, index) => {
					return {
						id: task?.id,
						dependency_order: index,
					};
				}),
			}),
		)
			.then(() => {
				setPreviousOrder(tasks);
				setUpdateTaskOrderLoading(false);
			})
			.catch((error) => {
				setTasks(previousOrder);
				dispatch(setSnackBarIsOpen(false, true, error.message));
				setUpdateTaskOrderLoading(false);
			});
	};

	useEffect(() => {
		setTasks(sortedTasks || []);
		setPreviousOrder(sortedTasks || []);
	}, [sortedTasks]);

	useEffect(() => {
		setExpandedStates((prevStates) => {
			if (tasks.length > prevStates.length) {
				return [...prevStates, ...new Array(tasks.length - prevStates.length).fill(true)];
			} else if (tasks.length < prevStates.length) {
				return prevStates.slice(0, tasks.length);
			}
			return prevStates;
		});
	}, [tasks.length]);

	return (
		<div className='workflow-editor-body'>
			<div
				className='d-flex align-items-center justify-content-between'
				style={{
					height: '47px',
					width: '100%',
					backgroundColor: '#FCFDFD',
					borderRadius: '4px',
					padding: '12px 18px',
					marginBottom: '16px',
				}}
			>
				<span className='HeadlineThreeBold'>Workflow Tasks</span>
				<div className='d-flex align-items-center' style={{ gap: '12px' }}>
					<span
						className='BodyTwoLight primary-text mt-1 pointer'
						onClick={toggleExpandAll}
					>
						{expandTasks ? 'Collapse Tasks' : 'Expand Tasks'}
					</span>
					<Ellipse />
					<div
						className='d-flex align-items-center pointer'
						style={{ gap: '6px' }}
						onClick={addNewTaskRow}
					>
						<AddIcon
							fontSize='small'
							sx={{
								color: '#fb7a03',
								fontSize: 21,
							}}
						/>
						<span className='BodyTwoLight primary-text mt-1'>Add New Task</span>
					</div>
				</div>
			</div>
			<div className='w-100'>
				{loading ? (
					<CircularProgress size={40} sx={{ color: '#FB7A03' }} disableShrink />
				) : tasks?.length > 0 ? (
					tasks?.map((task, index) => {
						const dependencyTitle =
							tasks.find((t) => t.id === task?.dependent_task_id)?.title || 'None';
						return (
							<TasksComponent
								key={task?.id}
								task={task}
								dependencyOrder={`${parseInt(task?.dependency_order) + 1}/${
									tasks?.length
								}`}
								dependencyTitle={dependencyTitle}
								index={index}
								expandTasks={expandTasks}
								handleDragStart={handleDragStart}
								handleDragOver={handleDragOver}
								handleDragEnd={handleDragEnd}
								draggedTask={draggedTask}
								onExpandChange={handleExpandChange}
							/>
						);
					})
				) : (
					<div className='workflow-bar d-flex justify-content-center mt-4'>
						<span className='BodyTwoLight' style={{ color: '#696F6C' }}>
							No Task in this Workflow
						</span>
					</div>
				)}
			</div>

			<TaskAlert
				message={
					'You cannot move this task before the dependent task. To place it to the desired position, you can remove the dependency.'
				}
				open={isAlert}
				header={'This task cannot be moved before the dependent task'}
				closeButton={'Cancel'}
				closeHandler={() => setIsAlert(false)}
			/>

			{updateTaskOrderLoading && (
				<div
					style={{
						height: '100%',
						width: '100%',
						backgroundColor: 'rgba(0, 0, 0, 0.05)',
						position: 'fixed',
						top: 0,
						bottom: 0,
					}}
				>
					<CircularProgress
						style={{
							color: '#fa7b03',
							height: '50px',
							width: '50px',
							marginTop: '47vh',
						}}
					/>
				</div>
			)}

			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						{ModalName === 'TEMPLATE_TASK' && (
							<DummyModal
								title={'Add New Task'}
								onClose={toggleModal}
								style={{ width: '900px' }}
								hideCrossIcon
							>
								<AddTemplateTask
									onSubmit={addTaskTemplate}
									onCancel={toggleModal}
									editor
									formValues={formProp}
									editTask={false}
								/>
							</DummyModal>
						)}
					</>
				</Modal>
			)}
		</div>
	);
}

export default WorkflowBuilderTasks;
