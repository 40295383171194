import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import FormButtonOne from '../Buttons/FormButtonOne';
import { ClickAwayListener } from '@mui/material';

function ColorPicker({ color, label, updateColor, header, name }) {
	const [newColor, setNewColor] = useState();
	const [isColorPicker, setIsColorPicker] = useState(false);

	const [formControl, setFormControl] = useState({
		loading: false,
		error: '',
	});

	const handleUpdateColor = async () => {
		setFormControl({
			...formControl,
			loading: true,
		});
		await updateColor({ [name]: newColor });
		setFormControl({
			...formControl,
			loading: false,
		});
		setIsColorPicker(false);
	};

	const showColorPicker = () => {
		setIsColorPicker(true);
	};

    const closeHandler = () => {
        setIsColorPicker(false);
        setNewColor(color);
    };

	const colorChangeHandler = (color) => {
		setNewColor(color);
	};

	useEffect(() => {
		setNewColor(color);
	}, [color]);

	return (
		<div
			className='d-flex align-items-center'
			style={{
				position: 'relative',
			}}
		>
			<span onClick={showColorPicker} className='BodyTwoLight pointer primary-text mr-2'>
				{label}
			</span>
			<div
				style={{
					width: '24px',
					height: '24px',
					borderRadius: '50%',
					backgroundColor: newColor,
					cursor: 'pointer',
				}}
				onClick={showColorPicker}
			></div>
			{isColorPicker && (
				<ClickAwayListener onClickAway={() => setIsColorPicker(false)}>
					<div className='color-picker d-flex flex-column align-items-center'>
						<div className='w-100 color-picker-header'>
							<h2 className='HeadlineTwoBold text-left'>{header}</h2>
						</div>
						<div className='d-flex align-items-center color-picker-body'>
							<SketchPicker
								width='90'
								onChangeComplete={(color) => colorChangeHandler(color.hex)}
								onChange={(color) => colorChangeHandler(color.hex)}
								disableAlpha
								color={newColor}
							/>
						</div>
						<span className='w-100 color-picker-error error-text BodyThreeLight'>
							{formControl?.error}
						</span>

						<div className='d-flex justify-content-between w-100 color-picker-footer'>
							<FormButtonOne
								onClick={closeHandler}
								className='secondary-btn'
							>
								Cancel
							</FormButtonOne>
							<FormButtonOne
								onClick={handleUpdateColor}
								disabled={formControl?.loading}
								loading={formControl?.loading}
							>
								Update
							</FormButtonOne>
						</div>
					</div>
				</ClickAwayListener>
			)}
		</div>
	);
}

export default ColorPicker;
